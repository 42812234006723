import { useToggle, upperFirst } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import React, { useEffect, useState } from "react";
import { auth, provider } from ".././configFirebase";
import { UserAuth } from '../context/AuthContext';
import GoogleImg from "../../public/Google__G__Logo.svg"
import { useNavigate } from 'react-router-dom';
import '@mantine/notifications/styles.css';
import { notifications } from '@mantine/notifications';
import { signInWithCredential, signInWithCustomToken, FacebookAuthProvider } from "firebase/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { i18n } from '@lingui/core';
import { I18nProvider, useLingui } from '@lingui/react';
import { defaultLocale, loadCatalog } from '../../i18n'
import { useEditProfileDetailsMutation } from '../redux/api/apiSlice';
import { useNavigation } from '../context/NavigationContext';


import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  Button,
  Image,
  Loader,
  Divider,
  Checkbox,
  Anchor,
  Center,
  Stack,
} from '@mantine/core';
import styles from '../styles/LoginSection.module.css'
import { GoogleButton } from '../components/SignWithGoogle'
import ShramINlogo from '../assets/logos/shraminLogo.png'
import { GoogleAuthProvider, RecaptchaVerifier, signInWithPhoneNumber, signInWithPopup } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '@mantine/core'
import { appToken } from '../redux/userSlice';
import { getMessaging, getToken } from "firebase/messaging";
import { CiMobile1 } from "react-icons/ci";
import { Trans, t } from '@lingui/macro';
import LoginShowcaseCarousel from '../components/LoginShowcaseCarousel'
import { IoLogoFacebook } from "react-icons/io5";
import { IoIosCall } from "react-icons/io";
import CustomModalInApp from '../components/CustomInstallModalInApp'
import { useSearchParams } from 'react-router-dom'


//i18n.activate(loadCatalog);

const languageOptions = [
  { value: 'en', label: 'English' },
  { value: 'hi', label: 'हिंदी' },
  { value: 'gu', label: 'ગુજરાતી' },
  { value: 'ma', label: 'मराठी' },
  { value: 'pu', label: 'ਪੰਜਾਬੀ' },
  { value: 'te', label: 'తెలుగు' },
  { value: 'ta', label: 'தமிழ்' },
  { value: 'be', label: 'বাংলা' },
  { value: 'od', label: 'ଓଡିଆ' },
  { value: 'ka', label: 'ಕನ್ನಡ' },


  // Add more language options as needed
];



const Login = ({ beforeInstallPrompt }) => {

  const [searchParams] = useSearchParams();
  const { i18n } = useLingui();
  var languageOrigin = localStorage.getItem('languageOrigin');
  const [selectedLanguage, setSelectedLanguage] = useState(languageOrigin);
  const [token, setToken] = useState("");
  const [data, setData] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [userPhone, setUserPhone] = useState(null);
  const { googleSignIn, user, facebookSignIn } = UserAuth();
  const [verificationshow, setVerificationShow] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadOtp, setLoadOtp] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState("");
  const [custominappModal, setCustomInappModal] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(true);
  const [showMobileTextField, setShowMobileTextField] = useState(false);
  const [showMobileLoader, setShowMobileLoader] = useState(false);

  const [editProfile] = useEditProfileDetailsMutation()
  const fcmTokenData = useSelector((state) => state?.user.fcmtoken)
  const [isTrueCaller, setIsTrueCaller] = useState(true)
  const [reqID, setReqID] = useState('')


  const collegeId = searchParams.get("cid"); // "text"



  const { prevUrl } = useNavigation() || { prevUrl: '/' };

  let baseUrl = '';
  let relativeUrl = '/';

  if (prevUrl && prevUrl.startsWith('http')) {
    baseUrl = new URL(prevUrl).origin;
    relativeUrl = prevUrl.replace(baseUrl, "");
  } else {
    console.warn("Invalid or missing prevUrl:", prevUrl);
  }

  //Msg91Code
  useEffect(() => {
    const configuration = {
      widgetId: "346661676c63343134323336",
      tokenAuth: "423027TFGdtyddpJu665aca03P1",
      exposeMethods: true,
      success: (data) => {
        // get verified token in response
        console.log('success response', data);
      },
      failure: (error) => {
        // handle error
        console.log('failure reason', error);
      }
    };

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = () => {
      if (window.initSendOTP) {
        window.initSendOTP(configuration);
      }
    };
    script.src = 'https://control.msg91.com/app/assets/otp-provider/otp-provider.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);





  useEffect(() => {
    if (collegeId) {
      localStorage.setItem("collegeId", collegeId)
    }
  }, [collegeId])






  useEffect(() => {

    setSelectedLanguage(languageOrigin);
    loadCatalog(languageOrigin);

  }, [selectedLanguage]);


  useEffect(() => {
    if (user) {
      navigate(relativeUrl ? relativeUrl : '/')
    }
  }, [user]);


  useEffect(() => {
    var iOS = /iPad|iPhone|iPod|Mac|Windows/.test(navigator.userAgent) && !window.MSStream;
    if (iOS) {
      setShowMobileTextField(false)
    }
  }, []);



  const installApp = () => {
    if (beforeInstallPrompt) {
      beforeInstallPrompt.prompt();
    }
  };


  const handleChange = (event) => {
    setChecked(null)
    setChecked(event)
    //console.log("ddddddddd",event)
  };


  useEffect(() => {
    if (checked != null) {
      //console.log("aaaaaaajlll", checked)
      localStorage.setItem('checked', checked)
    }
  }, [checked])



  const handleLanguageChange = (value) => {
    localStorage.setItem('languageOrigin', value);
    setSelectedLanguage(value);
    loadCatalog(value);
  };





  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn(checked);
      console.log("hello")



    } catch (error) {
      console.log(error);
    }

  };

  const handleFacebookSignIn = async () => {
    await facebookSignIn();
  };

  const sendOtp = async () => {

    if (phoneNumber === "") {
      notifications.show({
        color: 'red',
        message: i18n._(t`Please enter mobile number 🤥`),
      })
    } else if (phoneNumber.toString().length < 10) {
      notifications.show({
        color: 'red',
        message: i18n._(t`Please enter a valid mobile number 🤥`),
      })
    } else if (phoneNumber.toString().length > 10) {
      notifications.show({
        color: 'red',
        message: i18n._(t`Please enter a 10 digits mobile number 🤥`),
      })
    }
    else {
      try {
        setLoad(true)
        const mobileNo = `91${phoneNumber}`;
        window.sendOtp(
          mobileNo,
          (data) => {
            setReqID(data.message)
          }
          ,
          (error) => {
            notifications.show({
              color: 'red',
              message: error.message,
            })
          }
        );
        setVerificationShow(true)

      } catch (error) {
        console.error(error)
      }
    }



  };


  const verifyOtp = async () => {
    if (otp === "") {
      notifications.show({
        color: 'red',
        message: i18n._(t`Please enter OTP 🤥`),
      })
    } else if (otp.toString().length < 6) {
      notifications.show({
        color: 'red',
        message: i18n._(t`Please enter a valid OTP 🤥`),
      })
    } else if (otp.toString().length > 6) {
      notifications.show({
        color: 'red',
        message: i18n._(t`Please enter a valid OTP 🤥`),
      })
    }
    else {
      try {
        setLoadOtp(true)
        window.verifyOtp(
          Number(otp),
          async (data) => {
            const token = data.message

            const url = import.meta.env.VITE_BASE_URL + `/public/msg91/auth/candidate?token=${token}&phoneNo=${phoneNumber}`;

            const response = await fetch(`${url}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                "Accept": 'application/json'
              },
            });
            const data1 = await response.json()
            const customToken = data1.customToken
            signInWithCustomToken(auth, customToken).then((result) => {
              signInWithPhonefetchData(result);
            });

          },
          (error) => {
            setLoadOtp(false)
            notifications.show({
              color: 'red',
              message: error.message,
            })
          },
          reqID
        );


      } catch (error) {
        setLoadOtp(false)
        if (error) {
          notifications.show({
            color: 'red',
            message: i18n._(t`Invalid OTP`),
          })
        }
      }
    }


  }



  const signInWithPhonefetchData = async (dataPhone) => {
    const url = import.meta.env.VITE_BASE_URL + '/candidate/signup';
    const url1 = import.meta.env.VITE_BASE_URL + '/candidate';
    const apiUrl = fcmTokenData ? `${url}?fcmToken=${fcmTokenData}` : url

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${dataPhone?.user?.accessToken}`,
          "Accept": 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      const { candidateId, userId } = result

      localStorage.setItem('candidateId', candidateId)
      localStorage.setItem('userId', userId)
      setData(result);
      profileFetchData(dataPhone, userId)
      sendWhatsappPermissionInDatabase(dataPhone)
      console.log("Candidate API calling", result);
      // if (userId) {
      //   if (localStorage.getItem('utmParams')) {

      //     const jsonObject = {
      //       attribution_details: localStorage.getItem('utmParams')
      //     }
      //     editProfile(jsonObject)

      //   }
      // }

    } catch (error) {
      console.error('Error fetching data:', error);
    }


    try {
      const object = {
        mobileNoVerified: true,
        attribution_details: localStorage.getItem('utmParams')
      };

      editProfile(object).then((result) => {
        console.log("Response111-", result);
      }).catch((err) => {
        console.log(err)
      });



    } catch (error) {
      console.log(error)
    }



    //signInWithPhonefetchData();
  };



  const profileFetchData = async (dataPhone, userId) => {


    const url = import.meta.env.VITE_BASE_URL + '/candidate';


    try {
      const response = await fetch(`${url}?userId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${dataPhone?.user?.accessToken}`,
          "Accept": 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok in ProfileData');
      }

      const profileResult = await response.json();

      setProfileData(profileResult);
      if (profileResult?.tradeId == null) {
        navigate('/onboardingbasicinformation')
      }
      // else if(profileResult?.selected_location_id == null && profileResult?.locationId == null) {
      //   navigate('/joblocation')
      // }
      else if (profileResult?.name == null) {
        navigate('/createprofileonboarding')
      } else {
        navigate(relativeUrl ? relativeUrl : '/')
      }

      console.log("Candidate API calling for Profile", profileResult);

    } catch (error) {
      console.error('Error fetching profile data:', error);
    }


  };

  /////////////////////////////////////////////////////


  const sendWhatsappPermissionInDatabase = async (dataPhone) => {


    const url = import.meta.env.VITE_BASE_URL + '/candidate';

    const obj = {};
    obj.whatsAppConsent = checked;
    // console.log("checked", checked)


    try {
      const response = await fetch(`${url}`, {

        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + dataPhone?.user?.accessToken,
        },
        body: JSON.stringify(obj)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok in ProfileData');
      }

      const profileResult = await response.json();

    } catch (error) {
      console.error('Error fetching profile data:', error);
    }


  };



  /////////////////////////////////////////////////

  function loadGoogleOneTapScript() {
    return new Promise((resolve, reject) => {
      // Check if the script is already loaded
      if (window.google && window.google.accounts) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }


  // const trueCallerPopUpText = () => {
  //   setShowMobileLoader(true)

  // }



  const trueCallerPopUp = () => {
    var iOS = /iPad|iPhone|iPod|Mac|Windows/.test(navigator.userAgent) && !window.MSStream;
    if (!iOS) {
      try {
        const array = new Uint32Array(1);
        window.crypto.getRandomValues(array);
        const uniqueRequestNonce = `nonce-${array[0]}`;

        saveTrueCallerRequestId(uniqueRequestNonce);
        const truecallerURL = new URL("truecallersdk://truesdk/web_verify");
        localStorage.setItem('truecallerAuthStarted', 'true');
        localStorage.setItem('trueCallerAuthId', uniqueRequestNonce);

        truecallerURL.searchParams.append("type", "btmsheet");
        truecallerURL.searchParams.append("requestNonce", uniqueRequestNonce);
        truecallerURL.searchParams.append("partnerKey", import.meta.env.VITE_TRUECALLER_APP_KEY);
        truecallerURL.searchParams.append("partnerName", "ShramIN Jobs");
        truecallerURL.searchParams.append("skipOption", "useAnotherNum");
        truecallerURL.searchParams.append("privacyUrl", "https://www.shramin.com/policy");
        truecallerURL.searchParams.append("termsUrl", "https://www.shramin.com/termsconditions");

        // Add other parameters as needed
        window.location.href = truecallerURL.toString();
        setTimeout(function () {

          const reqId = localStorage.getItem('trueCallerAuthId');
          if (reqId) {
            trueCallerLogin(reqId);
          }
          trueCallerLogin(uniqueRequestNonce);

        }, 600);
      } catch (error) {
        setShowMobileTextField(false);
        // Handle the error here, you can log it or take appropriate action
        console.error('Error opening truecallerURL:', error);
      }
    } else {
      setShowMobileTextField(false);
    }


    setShowMobileTextField(true);


  }



  // Call this function when the page loads
  useEffect(() => {

    if (localStorage.getItem('truecallerAuthStarted') === true) {
      const uniqueId = localStorage.getItem('trueCallerAuthId')
      localStorage.removeItem('truecallerAuthStarted')
      trueCallerLogin(uniqueId);

    }

  }, []);




  const saveTrueCallerRequestId = async (uniqueId) => {
    const url = import.meta.env.VITE_BASE_URL + `/public/truecaller/${uniqueId}`;
    try {
      const response = await fetch(`${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Accept": 'application/json'
        },
      });

    } catch (e) {
      console.log(e)
    }
  }


  const trueCallerLogin = async (uniqueId) => {
    const url = import.meta.env.VITE_BASE_URL + `/public/truecaller/auth/${uniqueId}`;
    try {
      const response = await fetch(`${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Accept": 'application/json'
        },
      });
      const data = await response.json()
      //  alert("API Response: " + JSON.stringify(data));

      if (data?.customToken) {
        // If custom token is present
        signInWithCustomToken(auth, data.customToken).then((result) => {
          //setShowMobileLoader(true)
          signInWithPhonefetchData(result);
        });
      } else if (data?.status === "flow_invoked") {
        setShowMobileTextField(false);
        //setShowMobileLoader(true)
        // If status is 'flow_invoked', re-run the function
        await trueCallerLogin(uniqueId);
      } else if (data?.status === "use_another_number") {
        // If status is 'use_another_num', set 'isTrueCaller' to false
        setShowMobileTextField(true);
        //setShowMobileLoader(false)
      }
      else if (data?.status === null) {
        await trueCallerLogin(uniqueId);
      }
      // alert("API : " + JSON.stringify(data));

    } catch (e) {
      console.log(e)
      setShowMobileTextField(false)
    }
  }



  const customInAppModalOpen = () => {
    setCustomInappModal(true)
  }

  const customInAppModalClose = () => {
    setCustomInappModal(false)
  }




  return (

    <Paper radius="md" px={10} withBorder className={styles.mainContainer}>

      <div style={{ width: "150px", marginLeft: "10px", marginTop: "10px" }}>
        <Select
          key={i18n.locale}
          data={languageOptions}
          value={selectedLanguage}
          onChange={(event) => handleLanguageChange(event)}
          placeholder={t`Select Language`}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "14px 0" }}>

        <img src={ShramINlogo} className={styles.siteLogo} />
        <Text fw={500} style={{ display: "inline", fontSize: "30px" }}>
          ShramIN Jobs
        </Text>


      </div>

      <Center mb="md" style={{ width: "80%", margin: "12px auto" }}>
        <Text style={{ textAlign: "center" }} size="sm" fw={400}>
          <Trans>Job Search and Career Guidance App for ITI and Diploma Candidates</Trans>
        </Text>
      </Center>

      {/* {showMobileLoader &&
        <div>
         <Center>
            <Loader style={{ marginBottom: "15px" }} color="#034078" size={40} />
          </Center>
  </div>
      } */}

      {!showMobileTextField &&
        <div>
          <button onClick={trueCallerPopUp} className={styles.google_signin_btn} style={{ backgroundColor: "#02bd7e" }}>
            <div style={{ marginRight: "10px" }} >
              <IoIosCall size={25} />
            </div>
            <div style={{ color: "#fff" }}>
              <Trans>Sign in with Mobile</Trans>
            </div>
          </button>

        </div>
      }


      {/* <Group grow mb="md" mt="md"  >

        <div >
          <button onClick={handleFacebookSignIn} className={styles.google_signin_btn} style={{backgroundColor:"#3B5998", color:"#fff"}}>
            <div style={{ marginRight: "10px" ,marginTop:"2px"}} >
              <IoLogoFacebook size={25} />
            </div>
            <div style={{ color: "#fff" }}>
              <Trans>Sign in with Facebook</Trans>
            </div>
          </button>

        </div>

      </Group> */}

      <Group grow mb="md" mt="md"  >

        <div>
          <button onClick={handleGoogleSignIn} className={styles.google_signin_btn} style={{ backgroundColor: "#333333" }} >
            <div style={{ marginRight: "10px" }} >
              <Image height="25" width={"25"} src={GoogleImg} />
            </div>
            <div style={{ color: "#fff" }}>
              <Trans>Sign in with Google</Trans>
            </div>
          </button>

        </div>

      </Group>



      {/* <Divider label= {<Trans>Or continue with mobile Number</Trans>} labelPosition="center" my="lg" /> */}




      {/* <TextInput label="Email" placeholder="you@mantine.dev" required /> */}
      <div style={{ width: "80%", margin: "auto" }}>
        {!verificationshow && showMobileTextField && (

          <TextInput
            leftSectionPointerEvents="none"
            leftSection={'+91'}
            label={<Trans>Enter your mobile number</Trans>}
            //placeholder="Mobile Number"
            onChange={e => setPhoneNumber(e.target.value)}
            radius="md"
          />

        )}
        {!verificationshow && showMobileTextField && (
          <div>
            {load ? (
              <Button loading loaderProps={{ type: 'dots' }} style={{ marginBottom: "10px" }} fullWidth mt="xl">
                Loading button
              </Button>
            ) : (
              <Button style={{ marginBottom: "10px" }} onClick={sendOtp} fullWidth mt="xl">
                <Trans>Send OTP</Trans>
              </Button>
            )}
          </div>

        )}

        {!verificationshow && (
          <div id="recaptcha">
          </div>
        )}


        {verificationshow && (
          <TextInput
            key={i18n.locale}
            label={<Trans>Enter verification code</Trans>}
            placeholder={t`Enter OTP`}
            onChange={e => setOtp(e.target.value)}
            radius="md"
          />
        )}


        {verificationshow && (
          <div>
            {loadOtp ? (
              <Button loading loaderProps={{ type: 'dots' }} style={{ marginBottom: "50px" }} fullWidth mt="xl">
                Loading button
              </Button>
            ) : (
              <Button style={{ marginBottom: "50px" }} onClick={verifyOtp} fullWidth mt="xl">
                <Trans>Login</Trans>
              </Button>
            )}
          </div>
        )}
      </div>


      <Center style={{ marginTop: "20px" }}>
        <Checkbox
          color="green"
          iconColor="white"
          size="sm"
          label={<Trans>
            Yes, send me <FontAwesomeIcon icon={faWhatsapp} color='green' /> WhatsApp updates
          </Trans>}
          checked={checked}
          onChange={(event) => { handleChange(event.currentTarget.checked) }}
        //onChange={(event) => setChecked(event.currentTarget.checked)}
        />

      </Center>


      <div style={{ backgroundColor: "#0180ff", padding: "10px", borderRadius: "10px", textAlign: "center", marginTop: "1rem", display: !beforeInstallPrompt && "none" }}>
        <p style={{ color: "#fff", width: "100%", margin: "auto", textAlign: "center", marginBottom: "10px" }}><Trans>Job Search and Career Guidance App for ITI and Diploma Candidates</Trans></p>
        <button id="installBtn" disabled={!beforeInstallPrompt} onClick={installApp} style={{ backgroundColor: "#fff", color: "#0180ff", border: "none", borderRadius: "10px", padding: "5px 30px", cursor: "pointer", fontSize: "16px", fontWeight: "400" }}>
          <Trans>Install App</Trans>
        </button>
      </div>

      {!("Notification" in window) && (
        <div style={{ backgroundColor: "#0180ff", padding: "10px", borderRadius: "10px", textAlign: "center", marginTop: "2rem" }}>
          <p style={{ color: "#fff", width: "100%", margin: "auto", textAlign: "center", marginBottom: "10px" }}><Trans>Job Search and Career Guidance App for ITI and Diploma Candidates</Trans></p>
          <button id="installBtn" onClick={customInAppModalOpen} style={{ backgroundColor: "#fff", color: "#0180ff", border: "none", borderRadius: "10px", padding: "5px 30px", cursor: "pointer", fontSize: "16px", fontWeight: "400" }}>
            <Trans>Install App</Trans>
          </button>
        </div>
      )}

      <LoginShowcaseCarousel />
      {custominappModal && <CustomModalInApp handleModalClose={customInAppModalClose} />}
    </Paper>

  );
}
export default Login
