import React, { useEffect, useState } from 'react'
import Privatejobcard from '../components/Privatejobcard'
import { useSelector } from 'react-redux'
import { useGetOverseasJobQuery, useGetPrivateJobQuery } from '../redux/api/apiSlice'
import { Skeleton, Text } from '@mantine/core'
import Foreignjobcard from './Foreignjobcard'
// import FeatureJobModal from './FeatureJobModal'


const Foreignjobsection = () => {
    const token = useSelector((state) => state?.user.token)
    const [alljobs, setAllJobs] = useState([])
    const [loading, setloading] = useState(false)
    const candidateId = localStorage.getItem('candidateId')



    const { data: jobdata, isLoading, isError, isFetching, refetch } = useGetOverseasJobQuery({
        skip: !token
    })

     //console.log("uuuiiiiiuuuuuuiiiiiiii", alljobs)



    useEffect(() => {

        if (token && candidateId) {
            refetch();
        }
    }, [token, refetch]);


    useEffect(() => {
        if (jobdata) {
            setAllJobs(jobdata)
            setloading(false)
        }
    }, [jobdata]);




    return (
        <div style={{ width: "100%" }}>
            {loading ? (
                <>
                    <div style={{ marginBottom: "20px" }}>
                        <Skeleton height={50} circle mb="xl" />
                        <Skeleton height={8} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} width="100%" radius="xl" />
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                        <Skeleton height={50} circle mb="xl" />
                        <Skeleton height={8} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} width="100%" radius="xl" />
                    </div>
                    <div>
                        <Skeleton height={50} circle mb="xl" />
                        <Skeleton height={8} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} width="100%" radius="xl" />
                    </div>
                </>
            ) : (
                <>
                    {/* <FeatureJobModal /> */}
                    {alljobs.length > 0 ? (
                        alljobs.map(job => {
                            if (job.jobPostingTypeId == null) {
                                return <Foreignjobcard
                                    id={job?.id}
                                    title={job?.jobTitle}
                                    company={job?.companyName}
                                    trade={job?.trade}
                                    location={job?.location}
                                    state={job?.state}
                                    education={job?.education}
                                    experience={job?.experience}
                                    salary={job?.salary}
                                    logo={job?.logoVirtualPath ? job?.logoVirtualPath : job?.employerVirtual_Logo_Path}
                                />
                            }
                        })
                    ) : (
                        <div>
                            <Text style={{textAlign:"center", marginTop:"200px", marginBottom:"200px", fontSize:"20px"}}>Coming Soon</Text>
                        </div>
                    )}

                    <div style={{ marginTop: "60px", color:"white" }}>
                        <h6>.</h6>
                    </div>
                </>
            )}
        </div>
    )
}

export default Foreignjobsection