import React, { useState, useEffect, useRef } from 'react';
import { Text, Center, Card, Group, Chip, Button, TextInput, Paper, Loader, Modal, Stack, Box, Grid, rem } from '@mantine/core';
import { useMove } from '@mantine/hooks';
import { FaPeopleGroup } from "react-icons/fa6";
import classes from '../styles/OnboardingBasicInformationCard.module.css';
import { UserAuth } from '../context/AuthContext';
import master from '/src/data/master.json';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import { useWindowScroll } from '@mantine/hooks';
import { useDispatch, useSelector } from "react-redux";
import { onboardingField } from "../redux/userSlice";
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { HiSpeakerWave } from "react-icons/hi2";
import { RiSearchLine } from "react-icons/ri";
import { IoCheckmark } from "react-icons/io5";

import SwiperCore, { EffectCoverflow, Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { FaCirclePlus } from "react-icons/fa6";
import { FaCircleMinus } from "react-icons/fa6";
import { HiPlus } from "react-icons/hi";
import { HiMinus } from "react-icons/hi";


SwiperCore.use([EffectCoverflow, Navigation, Pagination, Autoplay]);

import Yes from "../../public/yes.png"
import Yes2 from "../../public/yes2.png"
import No from "../../public/remove.png"
import Remove from "../../public/remove2.png"
import Worker from "../../public/worker.png"

import '../screens/OnboardingStyle.css';


const OnboardingQuestions = () => {
  const { i18n } = useLingui();
  const { user } = UserAuth();
  var userId = localStorage.getItem('userId');
  const candidateId = localStorage.getItem('candidateId')

  const [proData, setProData] = useState([]);
  const dispatch = useDispatch();
  const [masterParentIdData, setMasterParentIdData] = useState(0);
  //const [page, setPage] = useState(0);
  const [masterParentIdFil, setMasterParentIdFil] = useState(null);
  const [masterOnboardingIdFil, setMasterOnboardingIdFil] = useState(null);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [selectedChips, setSelectedChips] = useState([]);
  const [selectedOptiononeId, setSelectedOptiononeId] = useState([]);
  const [selectedCourseOptionId, setSelectedCourseOptionId] = useState(null);

  const [noOptionData, setNoOptionData] = useState(0);
  const [onboardingParentIdData, setOnboardingParentIdData] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [openedOptionAlertEmpty, setOpenedOptionAlertEmpty] = useState(false);
  const [openedAlertEmpty, setOpenedAlertEmpty] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({}); // Modify state to hold selected options across pages
  const [selectedOptionsPost, setSelectedOptionsPost] = useState({});
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentBackPage, setcurrentBackPage] = useState(0);
  const [currentNextPage, setcurrentNextPage] = useState(0);
  const [resultBackPage, setResultCurrentBackPage] = useState(null);
  const [resultNextPage, setResultCurrentNextPage] = useState(null);
  const [searchQuery, setSearchQuery] = useState(''); // State to hold search query
  const posturl = import.meta.env.VITE_BASE_URL + `/candidate/onboarding/question`;
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [scroll, scrollTo] = useWindowScroll();
  const onboardingGetField = useSelector((state) => state?.user?.onboardingField);

  const [pursuingSelectoption, setPursuingSelectoption] = useState(false);
  const [careerguidSelectoption, setCareerguidSelectoption] = useState(false);

  

  const [updateDataEducation, setUpdateDataEducation] = useState(null);
  const [updateDataExperience, setUpdateDataExperience] = useState(null);
  const [tradeIdUpdate, setTradeIdUpdate] = useState(null);
  const [experienceTradeIdUpdate, setExperienceTradeIdUpdate] = useState(null);
  const [passingYearIdUpdate, setPassingYearIdUpdate] = useState(null);
  const [experienceUpdate, setExperienceUpdate] = useState(null);

  const [educationSaveUpdate, setEducationSaveUpdate] = useState(null);
  
  
  


  const [slides2, setSlides] = useState([])
  const [searchQuery2, setSearchQuery2] = useState("");
  const [filteredSlides, setFilteredSlides] = useState([]);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const [activeIndex, setActiveIndex] = useState(4);
  const [selectedHeading, setSelectedHeading] = useState()
  const [isChecked, setIsChecked] = useState(false);
  const [hide, setHide] = useState(false)
  const [backPage, setBackpage] = useState(false)
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(2);


  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear + 5 - (currentYear - 50) + 1 },
    (_, i) => currentYear - 50 + i
  );






  const swiperRef = useRef(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(23); // Renamed state
  const [selectedYear, setSelectedYear] = useState(null); // Initial year



  const handleYearClick = (year) => {
    console.log("Selected Year:", year);
    // Perform the desired action with the selected year
    handleOptionClick(selectedYear, 51, null, null, null)
  };

  const handleNextSlide = () => {
    swiperRef.current?.slideNext(); // Go to the next slide
    console.log("Selected Year on Plus:", selectedYear + 1);
    handleOptionClick(selectedYear + 1, 51, null, null, null)
  };

  const handlePrevSlide = () => {
    swiperRef.current?.slidePrev(); // Go to the previous slide
    console.log("Selected Year on Minus:", selectedYear - 1);
    handleOptionClick(selectedYear - 1, 51, null, null, null)
  };



  ///-----------------CAREER------------/////

  const wheelRefTwo = useRef(null);
  const [currentAngleTwo, setCurrentAngleTwo] = useState(300);
  const [isDraggingTwo, setIsDraggingTwo] = useState(false);
  const startAngleRefTwo = useRef(0);
  const lastAngleRefTwo = useRef(0);
  const segmentCenterAnglesTwo = [180, 60, 300];


  const getAngleTwo = (x, y) => {
    const rect = wheelRefTwo.current.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    return Math.atan2(y - centerY, x - centerX) * (180 / Math.PI);
  };

  const snapToSectionTwo = (angle) => {
    const normalizedAngle = ((angle % 360) + 360) % 360; // Normalize to [0, 360)
    let closestAngle = segmentCenterAnglesTwo.reduce((prev, curr) =>
      Math.abs(curr - normalizedAngle) < Math.abs(prev - normalizedAngle)
        ? curr
        : prev
    );
    return closestAngle;
  };

  const handleDragStartTwo = (x, y) => {
    setIsDraggingTwo(true);
    startAngleRefTwo.current = getAngleTwo(x, y);
    lastAngleRefTwo.current = startAngleRefTwo.current;
  };

  const handleDragMoveTwo = (x, y) => {
    if (!isDraggingTwo) return;

    const currentMouseAngle = getAngleTwo(x, y);
    const angleDifference = currentMouseAngle - lastAngleRefTwo.current;
    setCurrentAngleTwo((prevAngle) => prevAngle + angleDifference);

    lastAngleRefTwo.current = currentMouseAngle;
  };

  const handleDragEndTwo = () => {
    setIsDraggingTwo(false);

    const snappedAngle = snapToSectionTwo(currentAngleTwo);

    const normalizedCurrentAngle = ((currentAngleTwo % 360) + 360) % 360;
    const normalizedSnappedAngle = ((snappedAngle % 360) + 360) % 360;

    let angleDifference = normalizedSnappedAngle - normalizedCurrentAngle;
    if (angleDifference > 180) angleDifference -= 360;
    if (angleDifference < -180) angleDifference += 360;

    // Apply the shortest rotation
    setCurrentAngleTwo(currentAngleTwo + angleDifference);
  };

  const handleMouseDownTwo = (e) => {
    handleDragStartTwo(e.clientX, e.clientY);
  };

  const handleMouseMoveTwo = (e) => {
    handleDragMoveTwo(e.clientX, e.clientY);
  };

  const handleMouseUpOrLeaveTwo = () => {
    handleDragEndTwo();
  };

  const handleTouchStartTwo = (e) => {
    const touch = e.touches[0];
    handleDragStartTwo(touch.clientX, touch.clientY);
  };

  const handleTouchMoveTwo = (e) => {
    const touch = e.touches[0];
    handleDragMoveTwo(touch.clientX, touch.clientY);
  };

  const handleTouchEndTwo = () => {
    handleDragEndTwo();
  };

  const navigateTwo = useNavigate();


  const sectionsTwo = [
    { name: "Career Guidance", angle: 120 },
    { name: "Looking for a Job", angle: 0 },
    { name: "Looking for a Course", angle: 240 },
  ];

  const getSelectedSectionTwo = () => {
    const topOffset = 178;
    const adjustedAngle = (currentAngleTwo + topOffset) % 360;
    const normalizedAngle = (adjustedAngle + 360) % 360;
    const sectionIndex = Math.round(normalizedAngle / (360 / sectionsTwo.length)) % sectionsTwo.length;
    return sectionsTwo[sectionIndex];
  };

  const selectedSectionTwo = getSelectedSectionTwo();



  // ----------------EDUCATION SCREEN--------

  const wheelRef = useRef(null);
  const [currentAngle, setCurrentAngle] = useState(300);
  const [isDragging, setIsDragging] = useState(false);
  const startAngleRef = useRef(0);
  const lastAngleRef = useRef(0);
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(null);

  // Sections array
  const sections = [
    { name: "Below 10", angle: 0 },
    { name: "10th Pass", angle: 36 },
    { name: "12th Pass", angle: 72 },
    { name: "ITI", angle: 108 },
    { name: "Diploma", angle: 144 },
    { name: "Graduate", angle: 180 },
    { name: "Certification", angle: 216 },
    { name: "B.VOC", angle: 288 },
  ];

  // Calculate angle from coordinates
  const getAngle = (x, y) => {
    const rect = wheelRef.current.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    return Math.atan2(y - centerY, x - centerX) * (180 / Math.PI);
  };

  // Start drag logic
  const handleDragStart = (x, y) => {
    setIsDragging(true);
    startAngleRef.current = getAngle(x, y);
    lastAngleRef.current = startAngleRef.current;
  };

  const handleDragMove = (x, y) => {
    if (!isDragging) return;
    const currentMouseAngle = getAngle(x, y);
    const angleDifference = currentMouseAngle - lastAngleRef.current;
    setCurrentAngle((prevAngle) => prevAngle + angleDifference);
    lastAngleRef.current = currentMouseAngle;
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  // Align selected segment to 0 degrees
  const alignSelectedSegmentToTop = (segmentIndex) => {
    const anglePerSection = 360 / sections.length;
    const selectedSegmentAngle = segmentIndex * anglePerSection;
    const topOffset = 340; // Offset to align the selected segment to 180 degrees (180 + 178)
    const newAngle = topOffset - selectedSegmentAngle;

    setCurrentAngle((prevAngle) => prevAngle + (newAngle - (prevAngle % 360)));
  };

  // Event handlers
  const handleMouseDown = (e) => handleDragStart(e.clientX, e.clientY);
  const handleMouseMove = (e) => handleDragMove(e.clientX, e.clientY);
  const handleMouseUpOrLeave = () => handleDragEnd();
  const handleTouchStart = (e) => handleDragStart(e.touches[0].clientX, e.touches[0].clientY);
  const handleTouchMove = (e) => handleDragMove(e.touches[0].clientX, e.touches[0].clientY);
  const handleTouchEnd = () => handleDragEnd();


  // ***************************EXPERIENCE**********************

  const [selectedOptionExe, setSelectedOptionExe] = useState(null);
  const [valueExperience, setValueExperience] = useState(null);
  const [isInteracted, setIsInteracted] = useState(false);


  // Map slider positions to specific points (0, 0.33, 0.67, 1)
  const mapToDiscretePoints = (rawValue) => {
    const steps = [0, 0.30, 0.60, 0.9];

    const closestStep = steps.reduce((prev, curr) =>
      Math.abs(curr - rawValue) < Math.abs(prev - rawValue) ? curr : prev
    );
    return closestStep;
  };

  const { ref: refExperience } = useMove(({ y }) => {
    const continuousValue = 1 - y; // Invert because `y` is from 0 (top) to 1 (bottom)
    setValueExperience(mapToDiscretePoints(continuousValue)); // Snap to nearest point
    setIsInteracted(true);


  });

  const { ref: midrefExperience } = useMove(({ y }) => {
    const continuousValue = 1 - y; // Invert because `y` is from 0 (top) to 1 (bottom)
    setValueExperience(mapToDiscretePoints(continuousValue)); // Snap to nearest point
    setIsInteracted(true);


  });

  useEffect(() => {
    if (valueExperience == 0) {
      handleOptionClick(import.meta.env.VITE_SELECT_EXPERIENCE_ONE, 3, null, null, null)
      setSelectedOptionExe(import.meta.env.VITE_SELECT_EXPERIENCE_ONE);
    } else if (valueExperience == 0.3) {
      handleOptionClick(import.meta.env.VITE_SELECT_EXPERIENCE_THREE, 3, null, null, null)
      setSelectedOptionExe(import.meta.env.VITE_SELECT_EXPERIENCE_THREE);
    } else if (valueExperience == 0.6) {
      handleOptionClick(import.meta.env.VITE_SELECT_EXPERIENCE_FIVE, 3, null, null, null)
      setSelectedOptionExe(import.meta.env.VITE_SELECT_EXPERIENCE_FIVE);
    } else if (valueExperience == 0.9) {
      handleOptionClick(import.meta.env.VITE_SELECT_EXPERIENCE_TEN, 3, null, null, null)
      setSelectedOptionExe(import.meta.env.VITE_SELECT_EXPERIENCE_TEN);
    }
  }, [valueExperience])

  // console.log(valueExperience + "vvvvvvvv")


  /////////////////////////Complete Course//////////////////

  const [year, setYear] = useState(null); // Initial year set to null

  const handleDecrement = (item) => {
    const currentYear = year === null ? 2025 : year; // Start from 2025 if year is null
    if (currentYear > 2024) {
      const newYear = currentYear - 1;
      setYear(newYear);

      const selectedOption = item.options.find(
        (option) => parseInt(option.masterName) === newYear
      );


      if (selectedOption) {
        handleOptionClick(selectedOption.id, item.id, selectedOption.parentId, item.parent, selectedOption?.originalMasterId);
       // educationHandleOptionClick(selectedOption?.id, item?.id, selectedOption?.originalMasterId)
      }
    }
  };

  const handleIncrement = (item) => {
    const currentYear = year === null ? 2024 : year; // Start from 2025 if year is null
    if (currentYear < 2027) {
      const newYear = currentYear + 1;
      setYear(newYear);

      const selectedOption = item.options.find(
        (option) => parseInt(option.masterName) === newYear
      );



      if (selectedOption) {
        handleOptionClick(selectedOption.id, item.id, selectedOption.parentId, item.parent, selectedOption?.originalMasterId);
        // educationHandleOptionClick(selectedOption?.id, item?.id, selectedOption?.originalMasterId)
      }
    }
  };



  ///////////////////////////////////////////////////////////

  const collegeId = localStorage.getItem("collegeId")
  // const candidateId = localStorage.getItem("candidateId")


  const getAllCollages = async (collegeId) => {
    const url = import.meta.env.VITE_BASE_URL + `/master?masterTypeId=16&sort=true&active=true`;
    try {
      const response = await fetch(`${url}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const collages = await response?.json();
      const findCollage = await collages.find(college => college.id == collegeId)
      console.log("cccc-", findCollage)
      return findCollage;

    } catch (error) {
      console.error('Error:', error);
    }
  }


  useEffect(() => {
    const findCollage = async () => {
      if (collegeId) {
        if (user?.accessToken) {
          const findCollage = await getAllCollages(collegeId)
          if (findCollage) {
            const { id } = findCollage;
            const url = import.meta.env.VITE_BASE_URL + `/candidate`;
            try {
              const response = await fetch(`${url}`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${user?.accessToken}`,
                  Accept: 'application/json',
                },
                body: JSON.stringify({
                  candidateCollegeInstituteId: id
                })
              });

              if (!response.ok) {
                throw new Error('Network response was not ok');
              } else {
                localStorage.removeItem("collegeId")
              }

            } catch (error) {
              console.error('Error:', error);
            }
          }
        }
      }
    }
    findCollage();
  }, [user])



  useEffect(() => {

    const fetchData = async () => {

      // setSelectedOptionExeMain(null)
      // setValueExperienceMain(null)
      // setIsInteractedMain(false)

      setSelectedOptionExe(null)
      setValueExperience(null)
      setIsInteracted(false)

      // setSelectedSegmentIndexTwo(null)
      setSelectedSegmentIndex(null)

      setYear(null)

      setSelectedChips([])

      console.log("FetchDatamasterParentIdDatandonboardingParentIdData", masterParentIdData, onboardingParentIdData, currentPage)

      const url = import.meta.env.VITE_BASE_URL + `/candidate/onboarding/question?masterParentId=${selectedYear ? import.meta.env.VITE_SELECT_PASSINGYEAR : years.includes(masterParentIdData) ? import.meta.env.VITE_SELECT_PASSINGYEAR : masterParentIdData}&onboardingParentId=${onboardingParentIdData}&page=${currentPage}&size=1`;

      try {
        const response = await fetch(`${url}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.accessToken}`,
            Accept: 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok in ProfileData');
        }

        const profileResult = await response.json();
        setProData(profileResult?.dtos);
        setSelectedYear(null)


        if (!backPage && (selectedOptionId == import.meta.env.VITE_SELECT_DIPLOMA || selectedOptionId == import.meta.env.VITE_SELECT_BTECH || selectedOptionId == import.meta.env.VITE_SELECT_SHORT_TERM_DIPLOMA || selectedOptionId == import.meta.env.VITE_SELECT_SENIOR_SECONDARY || selectedOptionId == import.meta.env.VITE_SELECT_CITS || selectedOptionId == import.meta.env.VITE_SELECT_APPRENTICESHIP || selectedOptionId == import.meta.env.VITE_SELECT_ADVANCED_DIPLOMA)) {
          setIsLastPage(true)
        } else {
          setIsLastPage(profileResult?.lastPage);
        }

      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    if (user?.accessToken) {
      fetchData();
      // fetchCandidateProfileData();
    }
    setMasterParentIdData(0);
  }, [user, currentPage]);


  // useEffect(() => {
  //   if (selectedOptionId == 19634 || selectedOptionId == 19627 || selectedOptionId == 19630) {
  //     setIsLastPage(true)
  //   }
  // }, [selectedOptionId])


  const fetchCandidateProfileData = async () => {
    const url = import.meta.env.VITE_BASE_URL + `/candidate`;
    try {
      const response = await fetch(`${url}?userId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok in ProfileData');
      }

      const profileResult = await response.json();
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  //////////////////////////Experience Table//////////////////////////

  const experienceHandleOptionClick = async (optionId, questionId, originalMasterId) => {

    console.log('updateExperienceTrade:',optionId, questionId, originalMasterId, updateDataExperience);

   if(updateDataExperience !== null && updateDataExperience !== undefined){
     updateExperienceHandleOptionClick(optionId, questionId, originalMasterId);
   }else{

    console.log('updatepassing:',optionId, questionId, originalMasterId, updateDataExperience);

    const url = import.meta.env.VITE_BASE_URL + `/candidate/`;
    try {

      // const bodyData = {
      //   "tradeId": originalMasterId !== null ? originalMasterId : optionId
      // };

      const bodyData = questionId === 1 || questionId === 67
      ? { tradeId: originalMasterId !== null ? originalMasterId : optionId }
      : { experienceId: optionId };

      const response = await fetch(`${url}${candidateId}/multiple-experience`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
        body: JSON.stringify(bodyData),
      });

      const dataExperience = await response.json();
      console.log("responseExperience", dataExperience?.candidateExperienceId)
      setUpdateDataExperience(dataExperience?.candidateExperienceId)

      if (!response.ok) {
        throw new Error('Failed to submit onboardPost');
      }
    } catch (error) {
      console.error('Error submitting option ID:', error);
    }

  }

  }


  /////////////////update exp///////////////

 const updateExperienceHandleOptionClick = async (optionId, questionId, originalMasterId) => {


  console.log('uppppppexp:', optionId, questionId);

   const url = import.meta.env.VITE_BASE_URL + `/candidate/multiple-experience/`;
   try {



  const bodyData = questionId === 1 || questionId === 67
  ? {
      tradeId: originalMasterId !== null ? originalMasterId : optionId,
      experienceId: experienceUpdate !== null ? experienceUpdate : null
    }
  : {
      experienceId: optionId,
      tradeId: experienceTradeIdUpdate !== null ? experienceTradeIdUpdate : null
    };



     const response = await fetch(`${url}${updateDataExperience}`, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${user?.accessToken}`,
       },
       body: JSON.stringify(bodyData),
     });

     const updateDataexptable = await response.json();
     console.log("responseeexpUpdateexp", updateDataexptable)
      

     if (!response.ok) {
       throw new Error('Failed to submit onboardPost');
     }
   } catch (error) {
     console.error('Error submitting option ID:', error);
   }


 }


  //////////////////////////Education Table//////////////////////////


  const educationHandleOptionClick = async (optionId, questionId, originalMasterId) => {

    console.log('updatepassing:',optionId, questionId, originalMasterId, updateDataEducation);

   if(updateDataEducation !== null && updateDataEducation !== undefined){
     updateEducationHandleOptionClick(optionId, questionId, originalMasterId);
   }else{

    console.log('updatepassingedu:',optionId, questionId, originalMasterId, updateDataEducation);

    const url = import.meta.env.VITE_BASE_URL + `/candidate/`;
    try {
 
      // const bodyData = questionId === 61
      //   ? { tradeId: optionId }
      //   : { passingYear: optionId };

      const bodyData = questionId === 61
    ? { 
      tradeId: originalMasterId !== null ? originalMasterId : optionId,
      pursuing: pursuingSelectoption !== null ? pursuingSelectoption : null,
      educationId: educationSaveUpdate !== null ? educationSaveUpdate : null
      
     }
    : { 
      passingYear: optionId,
      pursuing: pursuingSelectoption !== null ? pursuingSelectoption : null,
      educationId: educationSaveUpdate !== null ? educationSaveUpdate : null
     };
 
 
      const response = await fetch(`${url}${candidateId}/multiple-education`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
        body: JSON.stringify(bodyData),
      });
 
      const dataEducation = await response.json();
       console.log("responseeducation", dataEducation?.candidateEducationId)
       setUpdateDataEducation(dataEducation?.candidateEducationId)
 
      if (!response.ok) {
        throw new Error('Failed to submit onboardPost');
      }
    } catch (error) {
      console.error('Error submitting option ID:', error);
    }

   }


 }

 /////////////////update///////////////

 const updateEducationHandleOptionClick = async (optionId, questionId, originalMasterId) => {


  console.log('uppppppedu:', optionId, questionId);

   const url = import.meta.env.VITE_BASE_URL + `/candidate/multiple-education/`;
   try {


  //   const bodyData = questionId === 61
  // ? { tradeId: optionId, passingYear: passingYearIdUpdate !== null ? passingYearIdUpdate : null } 
  // : { passingYear: optionId, tradeId: tradeIdUpdate !== null ? tradeIdUpdate : null };

  const bodyData = questionId === 61
  ? {
      tradeId: originalMasterId !== null ? originalMasterId : optionId,
      passingYear: passingYearIdUpdate !== null ? passingYearIdUpdate : null,
      pursuing: pursuingSelectoption !== null ? pursuingSelectoption : null,
      educationId: educationSaveUpdate !== null ? educationSaveUpdate : null
      
    }
  : {
      passingYear: optionId,
      tradeId: tradeIdUpdate !== null ? tradeIdUpdate : null,
      pursuing: pursuingSelectoption !== null ? pursuingSelectoption : null,
      educationId: educationSaveUpdate !== null ? educationSaveUpdate : null
    };



     const response = await fetch(`${url}${updateDataEducation}`, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${user?.accessToken}`,
       },
       body: JSON.stringify(bodyData),
     });

     const updateDataducationtable = await response.json();
     console.log("responseeducationUpdate", updateDataducationtable)
      

     if (!response.ok) {
       throw new Error('Failed to submit onboardPost');
     }
   } catch (error) {
     console.error('Error submitting option ID:', error);
   }


 }

  //////////////////////Career wheel////////////


  const handleNextPageCareer = async (questionId) => {
    let optionId;

    if (selectedSectionTwo.name === "Career Guidance") {
      optionId = import.meta.env.VITE_SELECT_CAREER_GUIDANCE;
    } else if (selectedSectionTwo.name === "Looking for a Job") {
      optionId = import.meta.env.VITE_SELECT_LOOKINGFORAJOB;
    } else if (selectedSectionTwo.name === "Looking for a Course") {
      optionId = import.meta.env.VITE_SELECT_LOOKINGFORACOURSE;
    }

    // Set state first
    // setMasterParentIdData(optionId);

    // Call handleOptionClick after setting the state
    handleOptionClick(optionId, questionId, null, null, null);
  };



  ////////////////////////////Select Options////////////////////////////////////



  const handleOptionClick = async (optionId, questionId, parentId, onbordingParentId, originalMasterId) => {

    console.log("ddddddkkkkkkk1", optionId, questionId, parentId, onbordingParentId, originalMasterId)



    // if (questionId == 52) {

    //   if (optionId == import.meta.env.VITE_SELECT_POST_GRADUATION || optionId == import.meta.env.VITE_SELECT_SECONDARY_EDUCATION || optionId == import.meta.env.VITE_SELECT_GRADUATION) {
    //     setIsLastPage(true)
    //   } else {
    //     setIsLastPage(false)
    //   }
    // }


    if (questionId === 1 || questionId === 67) {

      if(originalMasterId !== null){
        setExperienceTradeIdUpdate(originalMasterId);
      }else{
        setExperienceTradeIdUpdate(optionId);
      }

      experienceHandleOptionClick(optionId, questionId, originalMasterId)
    }

    if (questionId === 61) {
      if(originalMasterId !== null){
        setTradeIdUpdate(originalMasterId);
      }else{
        setTradeIdUpdate(optionId);
      }
     
      educationHandleOptionClick(optionId, questionId, originalMasterId)
    }

    if (questionId === 51) {
      setPassingYearIdUpdate(optionId);
      educationHandleOptionClick(optionId, questionId, originalMasterId)
    }

    if (questionId === 3) {
      setExperienceUpdate(optionId);
      experienceHandleOptionClick(optionId, questionId, originalMasterId)
    }

    if (questionId === 2) {
      setEducationSaveUpdate(optionId);
    }

    if (questionId === 43) {

      if (optionId == import.meta.env.VITE_SELECT_CAREER_GUIDANCE || optionId == import.meta.env.VITE_SELECT_LOOKINGFORACOURSE) {
        setCareerguidSelectoption(true)
        // console.log("ajdfhkdhlllllllllltrueeeee")
      } else if (optionId == import.meta.env.VITE_SELECT_LOOKINGFORAJOB ) {
        setCareerguidSelectoption(false)
        // console.log("ajdfhkdhllllllllllfalsee")
      }


      // handleNextPage()
    }

    if (questionId === 50) {
      // console.log("ajdfhkdhllllllllll")
      if (optionId == import.meta.env.VITE_SELECT_PURSUING) {
        setPursuingSelectoption(true)
       //  console.log("ajdfhkdhlllllllllltrueeeee")
      } else if (optionId == import.meta.env.VITE_SELECT_WORKING || optionId == import.meta.env.VITE_SELECT_WORKING_SELFEMPLOYED || optionId == import.meta.env.VITE_SELECT_FRESHER) {
        setPursuingSelectoption(false)
        // console.log("ajdfhkdhllllllllllfalsee")
      }
    }

    if (questionId === 54) {

      setSelectedChips((prevSelected) =>
        prevSelected.includes(optionId)
          ? prevSelected.filter((chip) => chip !== optionId)
          : [...prevSelected, optionId]
      );

      setSelectedOptiononeId(prev => Array.isArray(prev) ? [...new Set([...prev, optionId])] : [optionId]);
    } else {
      setSelectedOptionId(optionId);
    }


    const onboardingFieldRedux = {
      optionId,
      questionId
    };
    // fetchCandidateProfileData();
    dispatch(onboardingField(onboardingFieldRedux));
    setMasterParentIdData(optionId);
    setOnboardingParentIdData(questionId);
    try {
      const masterItem = master.find(item => item.id === questionId);
      const propertyName = masterItem ? masterItem.column_name : '';
      const noOption = masterItem ? masterItem.noOption : null;
      setNoOptionData(noOption);

      // Collect all selected option IDs
      const selectedOptionIds = Array.isArray(selectedOptionsPost[questionId]?.option_ids)
        ? [...new Set([...selectedOptionsPost[questionId]?.option_ids, optionId])]
        : [optionId];

      // // Prepare body data
      // const bodyData = {
      //   [propertyName]: propertyName === "candidateSkills"
      //     ? selectedOptionIds?.map(id => ({ "id": id }))
      //     : optionId,
      // };

      const bodyData = {
        [propertyName]: propertyName === "candidateSkills"
          ? selectedOptionIds?.map(id => ({ id }))
          : originalMasterId !== null && originalMasterId !== undefined
            ? originalMasterId
            : optionId,
      };
      

      const response = await fetch(`${posturl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
        body: JSON.stringify(bodyData),
      });

      if (!response.ok) {
        throw new Error('Failed to submit onboardPost');
      }



      // Update selectedOptionsPost state (for API management)
      setSelectedOptionsPost(prevSelectedOptions => ({
        ...prevSelectedOptions,
        [questionId]: {
          ...(questionId === 54
            ? { option_ids: selectedOptionIds } // Store all selected option IDs
            : { option_id: optionId }), // Store a single option ID
          parent_id: parentId,
          questionId: questionId,
          onbordingParentId: onbordingParentId,
          page: currentPage + 1,
        },
      }));

      setSelectedOptions(prevSelectedOptions => ({
        ...prevSelectedOptions,
        [questionId]: { "option_id": optionId, "parent_id": parentId, "questionId": questionId, "onbordingParentId": onbordingParentId, "page": currentPage + 1 },
      }));

    } catch (error) {
      console.error('Error submitting option ID:', error);
    }
  };

  //////////////////////////////////////

  const handleNextPage = async () => {

    // console.log("mmmmmmmmmmmmmmm", selectedOptions, currentPage)
    setBackpage(false)
    const resultArray = Object.values(selectedOptions);
    const filterArr = resultArray.filter((item) => {
      // console.log("a999a,", currentPage, item?.page + 1)
      return item?.page == currentPage
    })

    //console.log("schsncnbsnextttttt", masterParentIdData, resultArray)
    // console.log("33333", currentPage, filterArr)

    const optData = proData?.filter((item) => item?.id === 43);
    const optDataId = optData?.[0]?.id;

    // fetchBackData(currentPage - 1, filterArr[0]?.option_id, filterArr[0]?.questionId)


    // fetchBackData(currentPage)
    setSearchQuery('');
    scrollTo({ y: 0 });


    // console.log("ok testing",filterArr[0]?.option_id,"ques",currentPage,"option_id",selectedOptions[resultNextPage[0]?.id]?.option_id)
    if (!isLastPage) {
      if (masterParentIdData === undefined || masterParentIdData === 0 || masterParentIdData === null) {
       
          setOpenedOptionAlertEmpty(true);
        
      }
      else if (masterParentIdData !== undefined || masterParentIdData !== 0 || masterParentIdData !== null) {
        setProData(null)
        setCurrentPage(prevPage => prevPage + 1);
        //fetchNextData(currentPage+2)
      }
      else {
        setOpenedOptionAlertEmpty(true);
      }

    }


    setSelectedHeading(null)
  };


  const handleBackPage = async () => {
    setSelectedSlide(null)
    setBackpage(true)
    setSearchQuery('');

     /////////NEW//////////////

     const resultArray = Object.values(selectedOptions);

// Filter out items greater than the current page
const updatedResultArray = resultArray.filter((item) => {
  return item?.page <= currentPage - 1;
});

// Log the filtered array for debugging
//console.log("Filtered Array:", updatedResultArray, "Current Page:", currentPage);

// Update the state with the filtered array
setSelectedOptions(updatedResultArray);

// Process the items for the current page
const filterArr = updatedResultArray.filter((item) => item?.page === currentPage - 1);

console.log("Filtered Array:", filterArr,"Current Page:", currentPage, updatedResultArray);

if (currentPage == 4 && filterArr.length == 0) { 
  setMasterParentIdData(import.meta.env.VITE_SELECT_FRESHER);
  setOnboardingParentIdData(50);
}
if (currentPage == 5 && filterArr.length == 0) { 
  setMasterParentIdData(4);
  setOnboardingParentIdData(1);
}

setProData(null);

// Handle the first item in the filtered array
if (filterArr[0]?.option_id && filterArr[0]?.questionId !== undefined) {
  if (filterArr[0]?.page === 0) {
    setSelectedOptions([]);
    // fetchBackData(0, 0, 0);
  }
}

// Fetch or process based on current data
if (filterArr[0]?.option_id && filterArr[0]?.questionId !== undefined) {
  if (filterArr[0]?.page === 0) {
    setMasterParentIdData(0);
    setOnboardingParentIdData(0);
  } else {
      setMasterParentIdData(filterArr[0]?.option_id);
      setOnboardingParentIdData(filterArr[0]?.questionId);
  }
}



    ////////////OLD///////////

    // const resultArray = Object.values(selectedOptions);
    // const filterArr = resultArray.filter((item) => {
    //   // console.log("a999a,", currentPage, item?.page + 1)
    //   return item?.page == currentPage - 1
    // })

    // console.log("schsncnbsmbc",filterArr, currentPage, resultArray)


    // setProData(null)

    // // setSearchQuery('');
    // if (filterArr[0]?.option_id && filterArr[0]?.questionId !== undefined) {
    //   if (filterArr[0]?.page == 0) {
    //     setSelectedOptions(0)
    //     //fetchBackData(0, 0, 0)
    //   }

    // }

    // // fetchNextData(currentPage)
    // if (filterArr[0]?.option_id && filterArr[0]?.questionId !== undefined) {
    //   //console.log("SelectedOptionsdfndjnvd", currentPage )

    //   if (filterArr[0]?.page == 0) {
    //     setMasterParentIdData(0);
    //     setOnboardingParentIdData(0)
    //   }
    //   else {
    //     setMasterParentIdData(filterArr[0]?.option_id);
    //     setOnboardingParentIdData(filterArr[0]?.questionId)
    //   }



    // }

    ////////////////////////////////////

    // console.log("FetchDatamasterParentIdDatandonboardingParentIdData", masterParentIdData, onboardingParentIdData, currentPage)


    setCurrentPage(prevPage => Math.max(prevPage - 1, 0));
    // const selectedOptionId = selectedOptions[onboardingParentIdData];
    setSelectedYear(null)

    // if (currentPage > 8 && onboardingParentIdData == 58) {
    //   setMasterParentIdData(19566)
    //   setCurrentPage(8)
    //   setOnboardingParentIdData(43)
    // }
  };

  const handleSubmit = async () => {
    scrollTo({ y: 0 });
    if (masterParentIdData === 0 || masterParentIdData === null) {
      setOpenedOptionAlertEmpty(true);
    } else {
      navigate('/joblocation');
    }
  };

  const speakText = (text) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = i18n.language; // Set the language for speech synthesis
      window.speechSynthesis.speak(utterance);
    } else {
      console.error('Speech synthesis not supported in this browser.');
    }
  };



  const closeOptionAlertEmpty = () => {
    setOpenedOptionAlertEmpty(false);
  };

  const closeAlertEmpty = () => {
    setOpenedAlertEmpty(false);
  };

  // Function to handle search input change
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };



  const handleChange2 = (data, event) => {
    console.log("data" + event.target.checked)
    console.log(data)
    setHide(true)
    setTimeout(() => {
      setIsChecked(event.target.checked);
      setHide(false)
    }, 400)
    if (isChecked) {
      console.log("checked")
      handleOptionClick(data?.options[0].id, data?.id, data.options[0].parentId, data?.parent, null);
      setSelectedOptions(prevSelectedOptions => ({
        ...prevSelectedOptions,
        [data?.id]: { "option_id": data?.options[0].id, "parent_id": data.options[0].parentId },
      }));
    } else {
      console.log("unchecked")
      handleOptionClick(data?.options[1].id, data?.id, data.options[1].parentId, data?.parent, null);
      setSelectedOptions(prevSelectedOptions => ({
        ...prevSelectedOptions,
        [data?.id]: { "option_id": data?.options[1].id, "parent_id": data.options[1].parentId },
      }));
    }
  };

  return (
    <>


      <Modal
        withCloseButton={false}
        opened={openedOptionAlertEmpty}
        onClose={() => setOpenedOptionAlertEmpty(false)}
        centered
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
          <Text fw={500} style={{ color: "#1F7A8C", fontSize: "1.2rem", marginBottom: "1rem" }}>
            <Trans>Please Select Option</Trans>
          </Text>

          <Stack align="center">
            <Group position="center" mt="md">
              <Button style={{ borderColor: "#1F7A8C" }} variant="outline" onClick={closeOptionAlertEmpty}>
                <Text c="#1F7A8C" size="md" fw={500}>
                  <Trans>OK</Trans>
                </Text>
              </Button>
            </Group>
          </Stack>
        </div>
      </Modal>


      <Modal
        withCloseButton={false}
        opened={openedAlertEmpty}
        onClose={() => setOpenedAlertEmpty(false)}
        centered
      >
        <div style={{ display: "grid", gridTemplateColumns: "10% 90%", gridGap: "0px" }}>
          <div style={{ textAlign: "center" }}>
          </div>
          <Text align="left" fw={500} size="sm" mb="md">
            <Trans>This app is only for Blue and Gray Collar</Trans>
          </Text>
        </div>
        <Stack align="center">
          <Group position="center" mt="md">
            <Button onClick={closeAlertEmpty}><Trans>OK</Trans></Button>
          </Group>
        </Stack>
      </Modal>





      <div style={{
        // width: '100%', marginTop: "115px", 
        position: "fixed",
        width: '100%',
        //  display: "grid",
        maxWidth: "600px",
        // backgroundColor: "orange"
      }}>

        {/* <div style={{

          //  backgroundColor: "green"
        }}>
          <div style={{
            // marginTop: "-20px", marginBottom: "20px", marginLeft: "50px",marginRight: "50px",
            textAlign: "center", alignItems: "center", justifyContent: "center"
          }}>
            <Text style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
              <Trans>Basic Information</Trans>
            </Text>
          </div>
        </div> */}

        {/* //////////////////////////////////// */}

        <div>
          {proData?.length > 0 ? (
            proData?.map((item, index) => {
              return (
                <div key={index}>

                  <Card radius="md">


                    <div style={{ marginTop: '-10px' }}>



                      <div style={{ display: 'flex', alignItems: 'center' }}>

                        <Card
                          style={{
                            backgroundColor: 'white',
                            flex: '1',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: "row",
                            justifyContent: 'center', // Center content horizontally
                            padding: '15px', // Add padding to give space around content
                            // borderRadius: '5px',
                          }}
                          // withBorder
                          radius="md"
                        >

                          {(pursuingSelectoption === true && item.id == 2) ? (
                            <>
                              <Text
                                style={{
                                  fontWeight: 'bold',
                                  color: 'black',
                                  textAlign: 'center',
                                  fontSize: '18px',
                                  marginRight: '10px', // Space between text and icon
                                }}
                              >
                                <Trans>In which course are you pursuing?</Trans>


                              </Text>
                            </>

                          ) : (
                            <>

                              {(pursuingSelectoption === true && item.id == 51) ? (
                                <>

                                  <Text
                                    style={{
                                      fontWeight: 'bold',
                                      color: 'black',
                                      textAlign: 'center',
                                      fontSize: '18px',
                                      marginRight: '10px', // Space between text and icon
                                    }}
                                  >

                                    <Trans>When will be your course completed?</Trans>

                                  </Text>

                                </>
                              ) : (
                                <>

                                  {(careerguidSelectoption === true && item.id == 62) ? (
                                    <>

                                      <Text
                                        style={{
                                          fontWeight: 'bold',
                                          color: 'black',
                                          textAlign: 'center',
                                          fontSize: '18px',
                                          marginRight: '10px', // Space between text and icon
                                        }}
                                      >

                                        <Trans>Which trade do you need career guidance in?</Trans>


                                      </Text>

                                    </>
                                  ) : (
                                    <Text
                                      style={{
                                        fontWeight: 'bold',
                                        color: 'black',
                                        textAlign: 'center',
                                        fontSize: '18px',
                                        marginRight: '10px', // Space between text and icon
                                      }}
                                    >
                                      <Trans id={`question.${item.id}`}>{item?.displayName}</Trans>

                                      {/* <Trans id={`question.48`}>{item?.displayName}</Trans> */}

                                    </Text>

                                  )}
                                </>



                              )}
                            </>
                          )}

                          <HiSpeakerWave
                            style={{
                              color: 'black',
                              fontSize: '22px',
                              cursor: 'pointer',
                              backgroundColor: "#daebfc",
                              width: "40px",
                              height: "40px",
                              borderRadius: "20px",
                              padding: "10px"
                            }}
                            onClick={() => speakText(t(`question.${item.id}`))}
                          />
                        </Card>



                      </div>

                      {(item.id == 50 || item.id == 2 || item.id == 3 || item.id == 43 || item.id == 51 || item.id == 44 || item.id == 49 || item.id == 66 || item.id == 63) ? (
                        <></>
                      ) : (
                        <div style={{ justifyContent: 'center', marginTop: '5px' }}>

                          <TextInput
                            icon={<RiSearchLine size={18} />}
                            placeholder='Search options...'
                            value={searchQuery}
                            onChange={handleSearch}
                            size="md"
                            radius="lg"
                            styles={{
                              input: {
                                border: '1px solid #ddd',
                                padding: '10px 12px',
                                fontSize: '16px',
                                marginBottom: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // 3D shadow effect
                                transition: 'transform 0.2s ease, box-shadow 0.2s ease', // Smooth transition for interactions
                                backgroundColor: '#FFFFFF', // White background for contrast
                              },
                            }}
                          // styles={{
                          //   input: {
                          //     border: '1px solid #ddd',
                          //     padding: '10px 12px',
                          //     fontSize: '16px',
                          //     marginBottom: "10px"
                          //   },
                          // }}
                          />

                        </div>
                      )}


                    </div>



                    {/* /////////////////////Grid//////////////////////// */}

                    {(item.id == 44) ? (

                      <>
                        <div
                          style={{
                            height: '62vh', // Limit the height of the parent container
                            // overflowY: 'auto', // Apply scroll behavior to the parent
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Grid
                            gutter={{ base: 5, xs: 'md', md: 'md', xl: 30 }}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              // overflowY: 'auto',
                              maxHeight: '65vh',
                              padding: "5px",
                              alignItems: item?.options?.length < 3 ? 'center' : 'flex-start',
                            }}
                          >
                            {item?.options?.filter(option =>
                              option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                            )?.length > 0 ? (
                              item.options
                                .filter(option => option.masterName.toLowerCase().includes(searchQuery.toLowerCase()))
                                .map((option, optionIndex) => (
                                  <Grid.Col
                                    key={optionIndex}
                                    span={12}
                                    style={{
                                      paddingTop: '20px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Card
                                      onClick={() => {
                                        handleOptionClick(option.id, item?.id, option.parentId, item?.parent, option?.originalMasterId );
                                        setSelectedOptions(prevSelectedOptions => ({
                                          ...prevSelectedOptions,
                                          [item?.id]: { "option_id": option.id, "parent_id": option.parentId },
                                        }));
                                      }}
                                      style={{
                                        backgroundColor: masterParentIdData !== 0 && selectedOptionId === option.id ? '#daebfc' : '#FFFFFF',
                                        // selectedOptionId === option.id ? 'lightblue' : '#FFFFFF',
                                        borderRadius: '20px',
                                        padding: '0px',
                                        textAlign: 'center',
                                        height: '100%',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        cursor: "pointer",
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // 3D shadow effect
                                        transition: 'transform 0.2s ease, box-shadow 0.2s ease', // Smooth animation
                                      }}
                                      onMouseEnter={(e) => {
                                        if (selectedOptionId !== option.id) { // Only apply hover effects if not selected
                                          e.currentTarget.style.transform = 'translateY(-5px)';
                                          e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.3)';
                                        }
                                      }}
                                      onMouseLeave={(e) => {
                                        if (selectedOptionId !== option.id) { // Reset hover effects if not selected
                                          e.currentTarget.style.transform = 'translateY(0px)';
                                          e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
                                        }
                                      }}
                                    >
                                      <img
                                        src={option?.logoVirtualPath}
                                        alt="Logo"
                                        style={{
                                          width: '180px',
                                          height: '100px',
                                          // objectFit: 'contain', // Maintain aspect ratio
                                          marginRight: '10px',
                                          marginLeft: '-5px',
                                          marginTop: '0px',
                                          marginBottom: '0px',
                                        }}
                                      />
                                      <Text style={{ fontSize: '15px' }}>
                                        {option?.masterName}
                                      </Text>
                                    </Card>
                                  </Grid.Col>
                                ))
                            ) : (
                              <Text
                                style={{
                                  textAlign: 'center',
                                  paddingTop: '20px',
                                  paddingLeft: '20px',
                                  paddingRight: '20px',
                                  paddingBottom: '50px',
                                  color: 'gray',
                                }}
                              >
                                No data available
                              </Text>
                            )}
                          </Grid>

                        </div>


                      </>

                    ) : (


                      ////////////////////////state/////////////////////
                      <>
                        {(item.id == 13 || item.id == 64) ? (

                          <>
                            <div>
                              <Grid
                                gutter={{ base: 5, xs: 'md', md: 'md', xl: 30 }}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  overflow: 'auto',
                                  //  height: '100%',
                                  maxHeight: '71vh', // Respect the container's max-height
                                  width: '100%',
                                  padding: "5px",
                                  alignItems: item?.options?.length < 3 ? 'center' : 'flex-start',
                                }}
                              >
                                <>
                                  {item?.options?.filter(option =>
                                    option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                  )?.length > 0 ? (
                                    item.options
                                      .filter(option => option.masterName.toLowerCase().includes(searchQuery.toLowerCase()))
                                      .map((option, optionIndex) => (
                                        <Grid.Col
                                          key={optionIndex}
                                          span={12}
                                          style={{
                                            paddingTop: '12px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <Card
                                            onClick={() => {
                                              handleOptionClick(option.id, item?.id, option.parentId, item?.parent, option?.originalMasterId);
                                              setSelectedOptions(prevSelectedOptions => ({
                                                ...prevSelectedOptions,
                                                [item?.id]: { "option_id": option.id, "parent_id": option.parentId },
                                              }));
                                            }}
                                            style={{
                                              backgroundColor: masterParentIdData !== 0 && selectedOptionId === option.id ? '#daebfc' : '#FFFFFF',
                                              borderRadius: '20px',
                                              padding: '0px',
                                              textAlign: 'center',
                                              height: '100%',
                                              width: '100%',
                                              display: 'flex',
                                              cursor: "pointer",
                                              flexDirection: 'row',
                                              justifyContent: 'flex-start',
                                              alignItems: 'center',
                                              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // 3D shadow effect
                                              transition: 'transform 0.2s ease, box-shadow 0.2s ease', // Smooth animation
                                            }}
                                            onMouseEnter={(e) => {
                                              if (selectedOptionId !== option.id) { // Only apply hover effects if not selected
                                                e.currentTarget.style.transform = 'translateY(-5px)';
                                                e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.3)';
                                              }
                                            }}
                                            onMouseLeave={(e) => {
                                              if (selectedOptionId !== option.id) { // Reset hover effects if not selected
                                                e.currentTarget.style.transform = 'translateY(0px)';
                                                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
                                              }
                                            }}
                                          >
                                            <img
                                              src={option?.logoVirtualPath}
                                              alt="Logo"
                                              style={{
                                                width: '100px',
                                                height: '95px',
                                                marginRight: '20px',
                                                marginLeft: '10px',
                                                marginTop: '5px',
                                                marginBottom: '5px',
                                              }}
                                            />
                                            <Text style={{ fontSize: '15px' }}>
                                              {option?.masterName}
                                            </Text>
                                          </Card>
                                        </Grid.Col>
                                      ))
                                  ) : (
                                    <Text
                                      style={{
                                        textAlign: 'center',
                                        paddingTop: '20px',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        paddingBottom: '50px',
                                        color: 'gray',
                                      }}
                                    >
                                      No data available
                                    </Text>
                                  )}
                                  <div>
                                    <Text style={{ color: "white", marginTop: "50px" }}>.</Text>
                                  </div>

                                </>
                              </Grid>

                            </div>


                          </>

                        ) : (

                          // -------------------------EDUCATION - 2 ------------------

                          <>
                            {(item.id == 2) ? (
                              <div>
                                <Card style={{ marginTop: "-10px" }}>
                                  <Center>
                                    <Text
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                        textAlign: "center",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <Trans>Please select on any one option</Trans>
                                    </Text>
                                  </Center>

                                  <div className="main_wheel_on">
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60vh",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "100vh",
                                        }}
                                        onMouseMove={handleMouseMove}
                                        onMouseUp={handleMouseUpOrLeave}
                                        onMouseLeave={handleMouseUpOrLeave}
                                        onTouchMove={handleTouchMove}
                                        onTouchEnd={handleTouchEnd}
                                        onTouchStart={handleTouchStart}
                                      >
                                        {selectedSegmentIndex !== null ? (
                                          <Center>
                                            <Text style={{ marginTop: "-50px", marginLeft: "0px", fontWeight: "500", fontSize: "20px", textAlign: "center" }}>
                                              {item?.options[selectedSegmentIndex]?.masterName}
                                            </Text>
                                          </Center>
                                        ) : (
                                          <Center>
                                            <Text style={{ marginTop: "-50px", marginLeft: "50px", fontWeight: "100", fontSize: "15px", color: "white", textAlign: "center" }}>
                                              .
                                            </Text>
                                          </Center>
                                        )}
                                        <div
                                          className="wheel-container_on"
                                          onMouseMove={handleMouseMove}
                                          onMouseUp={handleMouseUpOrLeave}
                                          onMouseLeave={handleMouseUpOrLeave}
                                          onTouchMove={handleTouchMove}
                                          onTouchEnd={handleTouchEnd}
                                        >
                                          <div
                                            className="wheel_on"
                                            ref={wheelRef}
                                            style={{
                                              transform: `rotate(${currentAngle}deg)`,
                                              transition: "transform 0.1s ease-out",
                                            }}
                                            onMouseDown={handleMouseDown}
                                            onTouchStart={handleTouchStart}
                                          >
                                            {item?.options
                                              ?.filter((option) =>
                                                option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                              )
                                              .map((option, optionIndex) => (
                                                <div
                                                  key={optionIndex}
                                                  className={`segment_on segment${optionIndex + 1}_on ${selectedSegmentIndex === optionIndex ? "selected" : ""
                                                    }`}
                                                  style={{
                                                    transform: `rotate(${(360 / sections.length) * optionIndex
                                                      }deg) translate(0%, -100%)`,
                                                  }}
                                                  onClick={() => {
                                                    handleOptionClick(option.id, item?.id, option.parentId, item?.parent, option?.originalMasterId);
                                                    setSelectedSegmentIndex(optionIndex);

                                                    // Align the selected segment to 0 degrees
                                                    alignSelectedSegmentToTop(optionIndex);

                                                    // Update the selectedOptions state
                                                    setSelectedOptions(prevSelectedOptions => ({
                                                      ...prevSelectedOptions,
                                                      [item?.id]: { "option_id": option.id, "parent_id": option.parentId },
                                                    }));
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      transform: `rotate(-${(360 / sections.length) * optionIndex
                                                        }deg)`,
                                                    }}
                                                  >
                                                    <Text
                                                      style={{
                                                        fontSize: "13px",
                                                        paddingTop: "30px",
                                                        paddingRight: "30px",
                                                        userSelect: "none"
                                                      }}
                                                    >
                                                      {option.masterName}
                                                    </Text>
                                                  </span>
                                                </div>
                                              ))}
                                          </div>
                                          <div style={{ marginTop: "11px", marginLeft: "8px" }} className="arrowTwo"></div>
                                        </div>
                                      </Box>
                                    </div>
                                  </div>
                                </Card>
                              </div>
                            ) : (

                              <>
                                {(item.id == 7) ? (
                                  <div style={{
                                    //marginTop: '10px',
                                    //backgroundColor: "pink",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '55vh',
                                    //marginBottom: '80px',
                                    // marginRight: "10px",
                                    flex: 1,
                                    //overflowY: 'auto',
                                    //zIndex: "1",
                                  }}>



                                    <Chip.Group>
                                      <Group justify="center" mt="md">
                                        {item?.options?.filter(option =>
                                          option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                        )?.length > 0 ? (
                                          item.options
                                            .filter(option => option.masterName.toLowerCase().includes(searchQuery.toLowerCase()))
                                            .map((option, optionIndex) => {
                                              const isChecked = masterParentIdData !== 0 ? selectedOptions[item?.id]?.option_id === option.id : false;



                                              return (
                                                <Chip
                                                  key={optionIndex}
                                                  style={{ marginTop: '10px', }}
                                                  color="#daebfc"
                                                  variant={
                                                    masterParentIdData != 0 && selectedOptions[item?.id]?.option_id === option.id
                                                      ? 'filled' // Use "filled" when selected
                                                      : 'outline' // Default variant
                                                  }
                                                  icon={
                                                    masterParentIdData != 0 && selectedOptions[item?.id]?.option_id === option.id && (
                                                      <IoCheckmark style={{ color: "black", fontSize: "16px", }} /> // Blue tick icon
                                                    )
                                                  }
                                                  checked={masterParentIdData != 0 ? selectedOptions[item?.id]?.option_id === option.id : ""}
                                                  onChange={() => setSelectedOptions(prevSelectedOptions => ({
                                                    ...prevSelectedOptions,
                                                    [item?.id]: { "option_id": option.id, "parent_id": option.parentId },
                                                  }))}
                                                  size='lg'
                                                  onClick={() => handleOptionClick(option.id, item?.id, option.parentId, item?.parent, option?.originalMasterId)}
                                                >
                                                  <Text style={{ fontSize: '14px', color: "black" }}>
                                                    {option?.masterName}
                                                  </Text>
                                                </Chip>

                                              );
                                            })
                                        ) : (
                                          <Text style={{ textAlign: 'center', paddingTop: '20px', paddingLeft: "20px", paddingRight: '20px', paddingBottom: '50px', color: 'gray' }}>
                                            No data available
                                          </Text>
                                        )}
                                      </Group>
                                    </Chip.Group>

                                  </div>
                                ) : (

                                  <>
                                    {(item.id == 54) ? (
                                      <>
                                        <div style={{
                                          //marginTop: '10px',
                                          //backgroundColor: "pink",
                                          display: 'flex',
                                          flexDirection: 'column',
                                          overflowY: 'auto',
                                          maxHeight: '55vh',
                                          //marginBottom: '80px',
                                          // marginRight: "10px",
                                          flex: 1,
                                          //overflowY: 'auto',
                                          //zIndex: "1",
                                        }}>


                                          <Chip.Group multiple>
                                            <Group justify="center" mt="md">
                                              {item?.options?.filter(option =>
                                                option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                              )?.length > 0 ? (
                                                item.options
                                                  .filter(option =>
                                                    option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                                  )
                                                  .map((option, optionIndex) => {
                                                    // Get the selected options for this question
                                                    const selectedForQuestion = Array.isArray(selectedOptions[item?.id]?.option_ids)
                                                      ? selectedOptions[item?.id]?.option_ids
                                                      : [];

                                                    // Determine if this option is selected
                                                    const isChecked = selectedForQuestion.includes(option.id);

                                                    return (
                                                      <Chip
                                                        key={optionIndex}
                                                        value={option?.id}
                                                        color="#daebfc"
                                                        // variant="outline"
                                                        variant={selectedChips.includes(option?.id) ? "filled" : "outline"}
                                                        checked={selectedChips.includes(option?.id)}
                                                        style={{
                                                          borderRadius: "20px",
                                                          marginTop: "10px",
                                                          // backgroundColor: isChecked ? "#007bff" : "transparent", // Highlight when selected
                                                          // color: isChecked ? "#fff" : "#000", // Adjust text color for visibility
                                                        }}
                                                        icon={
                                                          selectedChips.includes(option?.id) && (
                                                            <IoCheckmark style={{ color: "black", fontSize: "16px", }} /> // Blue tick icon
                                                          )
                                                        }
                                                        // checked={isChecked}
                                                        onChange={() => {
                                                          // Update selected options for highlighting
                                                          setSelectedOptions((prevSelectedOptions) => {
                                                            const currentSelections = Array.isArray(prevSelectedOptions[item?.id]?.option_ids)
                                                              ? prevSelectedOptions[item?.id]?.option_ids
                                                              : [];

                                                            // Toggle the current option selection
                                                            const updatedSelections = isChecked
                                                              ? currentSelections.filter(id => id !== option.id) // Remove if already selected
                                                              : [...currentSelections, option.id]; // Add if not selected

                                                            return {
                                                              ...prevSelectedOptions,
                                                              [item?.id]: {
                                                                ...prevSelectedOptions[item?.id],
                                                                option_ids: updatedSelections,
                                                              },
                                                            };
                                                          });

                                                          // Call the handler function
                                                          handleOptionClick(option.id, item?.id, option.parentId, item?.parent, option?.originalMasterId);
                                                        }}
                                                        size="lg"
                                                      >
                                                        <Text style={{ fontSize: "14px", color: "black" }}>
                                                          {option?.masterName}
                                                        </Text>
                                                      </Chip>
                                                    );
                                                  })
                                              ) : (
                                                <Text
                                                  style={{
                                                    textAlign: "center",
                                                    paddingTop: "20px",
                                                    paddingLeft: "20px",
                                                    paddingRight: "20px",
                                                    paddingBottom: "50px",
                                                    color: "gray",
                                                  }}
                                                >
                                                  No data available
                                                </Text>
                                              )}
                                            </Group>
                                          </Chip.Group>






                                        </div>


                                      </>
                                    ) : (

                                      // ***************************Course**********************
                                      <>
                                        {(item.id == 52 || item.id == 57) ? (
                                          <>
                                            <div style={{ paddingTop: "2px" }}>
                                              <div className="container_course">
                                                <div className="circle_container_course">
                                                  {item?.options
                                                    ?.filter((option) =>
                                                      option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                                    )
                                                    .map((option, optionIndex) => {
                                                      const textParts = option.masterName.split(" "); // Split by space

                                                      // Limit the length of masterName to 15 characters
                                                      const limitedMasterName =
                                                        option.masterName.length > 15
                                                          ? option.masterName.substring(0, 15)
                                                          : option.masterName;

                                                      const fixedSize = 120;

                                                      const colorPalette = ["#adfbb5", "#ddff9a", "#d0b2fe", "#faf7ac", "#f9a4c8"];

                                                      const isSelected =
                                                        masterParentIdData !== 0 && selectedCourseOptionId === option.id;
                                                      const backgroundColor =
                                                        masterParentIdData !== 0 && isSelected
                                                          ? "#7db9fc"
                                                          : colorPalette[optionIndex % colorPalette.length];
                                                      const borderWidth = isSelected ? "3px" : "0px"; // Add dynamic border width
                                                      const borderColor = isSelected ? "#0190ff" : "transparent"; // Add dynamic border color

                                                      return (
                                                        <div
                                                          key={option.id}
                                                          className="circle_course"
                                                          style={{
                                                            width: `${fixedSize}px`,
                                                            height: `${fixedSize}px`,
                                                            top: `${option.top}px`,
                                                            left: `${option.left}px`,
                                                            backgroundColor: backgroundColor,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            textAlign: "center",
                                                            whiteSpace: "normal",
                                                            userSelect: "none",
                                                            margin: "10px",
                                                            borderWidth: borderWidth,
                                                            borderColor: borderColor,
                                                            borderStyle: "solid", // Ensure border is styled properly
                                                          }}
                                                          onClick={() => {
                                                            handleOptionClick(option.id, item?.id, option.parentId, item?.parent, option?.originalMasterId);

                                                            setSelectedCourseOptionId(option.id);

                                                            setSelectedOptions((prevSelectedOptions) => ({
                                                              ...prevSelectedOptions,
                                                              [item?.id]: { option_id: option.id, parent_id: option.parentId },
                                                            }));
                                                          }}
                                                        >
                                                          {textParts.length > 1 ? (
                                                            <>
                                                              <span style={{ fontSize: "14px" }}>{textParts[0]}</span>
                                                              <span style={{ fontSize: "14px" }}>{textParts.slice(1).join(" ")}</span>
                                                            </>
                                                          ) : (
                                                            <span style={{ fontSize: "14px" }}>{option.masterName}</span>
                                                          )}
                                                        </div>
                                                      );
                                                    })
                                                    .reverse()}
                                                </div>
                                              </div>
                                            </div>

                                          </>

                                        ) : (

                                          // ***************************EXPERIENCE**********************

                                          <>
                                            {(item.id == 3) ? (
                                              <>
                                                <div className="container_experience">
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      height: "60vh",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {/* Left Section: Experience Options (Filtered from Backend Data) */}
                                                    <div style={{ flex: 1 }}>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "column",
                                                          justifyContent: "space-between",
                                                          gap: "3.2rem",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        {item?.options
                                                          ?.filter((option) =>
                                                            option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                                          )
                                                          .filter((option) => item?.id !== 3 || option?.id !== 30)
                                                          .slice(0, 4)
                                                          .reverse()
                                                          .map((option) => (
                                                            <span
                                                              key={option.id}
                                                              style={{
                                                                minWidth: "100px",

                                                                fontWeight: selectedOptionExe === option.id ? "bold" : "normal",
                                                                color: selectedOptionExe === option.id ? "#3280ff" : "inherit",
                                                              }}
                                                            >
                                                              {option.masterName}
                                                            </span>
                                                          ))}
                                                      </div>
                                                    </div>

                                                    {/* Middle Section: Slider Bar */}
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        flex: 1,
                                                      }}
                                                    >
                                                      <Group justify="center">
                                                        <div
                                                          ref={refExperience}
                                                          style={{
                                                            width: "20px",
                                                            height: "260px",
                                                            backgroundColor: isInteracted
                                                              ? "var(--mantine-color-blue-light)"
                                                              : "lightgray",
                                                            position: "relative",
                                                            borderRadius: "10px",
                                                          }}
                                                        >
                                                          {/* Filled bar */}
                                                          <div
                                                            style={{
                                                              position: "absolute",
                                                              bottom: 0,
                                                              height: `${valueExperience * 100}%`,
                                                              width: "20px",
                                                              backgroundColor: isInteracted ? "#c1e5ff" : "lightgray",
                                                              opacity: 0.7,
                                                              borderRadius: "10px",
                                                              boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
                                                            }}
                                                          />
                                                          {/* Thumb */}
                                                          <div
                                                            style={{
                                                              position: "absolute",
                                                              bottom: `calc(${valueExperience * 100}% - 8px)`,
                                                              left: "-12px",
                                                              width: "45px",
                                                              height: "45px",
                                                              borderRadius: "50%",
                                                              backgroundColor: isInteracted ? "#6da8fe" : "lightgray",
                                                              boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
                                                              cursor: "pointer",
                                                            }}
                                                          />
                                                        </div>
                                                      </Group>
                                                    </div>

                                                    {/* Right Section: Icon Buttons */}
                                                    <div
                                                      ref={midrefExperience}
                                                      style={{
                                                        flex: 1,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        gap: "2.2rem",

                                                      }}
                                                    >
                                                      {item?.options
                                                        ?.filter((option) =>
                                                          option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                                        )
                                                        .filter((option) => item?.id !== 3 || option?.id !== 30)
                                                        .slice(0, 4)
                                                        .reverse()
                                                        .map((option) => (
                                                          <div
                                                            key={option.id}
                                                            style={{
                                                              display: "flex", // Align horizontally
                                                              alignItems: "center",
                                                              justifyContent: "space-between", // Space between year and icon
                                                              gap: "1rem", // Spacing between year and icon
                                                              border: "1px solid #3280ff",

                                                              height: "40px",
                                                              padding: "0 1rem",
                                                              borderRadius: "10px",
                                                              boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
                                                              cursor: "pointer",
                                                              backgroundColor:
                                                                selectedOptionExe === option.id ? "#c1e5ff" : "transparent",
                                                            }}
                                                          >


                                                            {/* FaPeopleGroup Icon */}
                                                            <FaPeopleGroup
                                                              style={{
                                                                color: selectedOptionExe === option.id ? "#3280ff" : "#3280ff",
                                                              }}
                                                            />
                                                          </div>
                                                        ))}
                                                    </div>
                                                  </div>
                                                </div>

                                              </>
                                            ) : (

                                              <>
                                                {(item.id == 50) ? (

                                                  <>


                                                    <div style={{ marginTop: "100px", margin: "0px" }}>
                                                      <Grid
                                                        gutter={{ base: 5, xs: 'md', md: 'md', xl: 30 }}
                                                        className='current_container'
                                                        style={{

                                                          alignItems: item?.length < 3 ? 'center' : 'flex-start',
                                                        }}
                                                      >
                                                        {item?.options
                                                          ?.filter(option =>
                                                            option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                                          )
                                                          .map((option, optionIndex, optionsArray) => {
                                                            return (
                                                              <Grid.Col
                                                                key={optionIndex}
                                                                span={6}
                                                                style={{
                                                                  paddingTop: '15px',
                                                                  // paddingLeft: '15px',
                                                                  display: 'flex',
                                                                  justifyContent: 'center',
                                                                }}
                                                              >
                                                                <Card
                                                                  style={{
                                                                    backgroundColor:
                                                                      masterParentIdData !== 0 && selectedOptionId === option.id
                                                                        ? '#daebfc'
                                                                        : 'white',
                                                                    borderRadius: '20px',
                                                                    padding: '20px',
                                                                    textAlign: 'center',
                                                                    height: '180px', // Consistent height
                                                                    width: '180px',  // Consistent width
                                                                    display: 'flex',
                                                                    color: 'black',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    cursor: 'pointer',
                                                                    boxShadow:
                                                                      '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
                                                                    transition: 'transform 0.2s, background-color 0.2s',
                                                                  }}
                                                                  onClick={() => {
                                                                    handleOptionClick(
                                                                      option.id,
                                                                      item?.id,
                                                                      option.parentId,
                                                                      item?.parent,
                                                                      option?.originalMasterId
                                                                    );
                                                                    setSelectedOptions(prevSelectedOptions => ({
                                                                      ...prevSelectedOptions,
                                                                      [item?.id]: {
                                                                        option_id: option.id,
                                                                        parent_id: option.parentId,
                                                                      },
                                                                    }));
                                                                  }}
                                                                >
                                                                  <img
                                                                    src={option?.logoVirtualPath}
                                                                    alt="Logo"
                                                                    style={{
                                                                      borderRadius: "15px 15px 0px 0px",
                                                                      width: '115%', // Relative width for scaling
                                                                      height: 'auto', // Maintain aspect ratio
                                                                      // objectFit: 'contain',
                                                                    }}
                                                                  />
                                                                  <Text style={{ marginTop: '10px', fontSize: '14px' }}>
                                                                    {option?.masterName}
                                                                  </Text>
                                                                </Card>
                                                              </Grid.Col>
                                                            );
                                                          })}
                                                      </Grid>
                                                    </div>
                                                  </>

                                                ) : (

                                                  <>
                                                    {(item.id == 43) ? (

                                                      <>

                                                        {/* /////////////////Career//////////////////// */}


                                                        <div
                                                                                style={{
                                                                                  height: '62vh', // Limit the height of the parent container
                                                                                  // overflowY: 'auto', // Apply scroll behavior to the parent
                                                                                  display: 'flex',
                                                                                  flexDirection: 'column',
                                                                                  // backgroundColor:"yellow",
                                                                                  paddingTop: "15px",
                                                                                }}
                                                                              >
                                                                                <Grid
                                                                                  gutter={{ base: 5, xs: 'md', md: 'md', xl: 30 }}
                                                                                  style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    // overflowY: 'auto',
                                                                                    maxHeight: '65vh',
                                                                                    padding: "5px",
                                                                                    alignItems: item?.options?.length < 3 ? 'center' : 'flex-start',
                                                                                  }}
                                                                                >
                                                                                  {item?.options?.filter(option =>
                                                                                    option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                                                                  )?.length > 0 ? (
                                                                                    item.options
                                                                                      .filter(option => option.masterName.toLowerCase().includes(searchQuery.toLowerCase()))
                                                                                      .reverse()
                                                                                      .map((option, optionIndex) => (
                                                                                        <Grid.Col
                                                                                          key={optionIndex}
                                                                                          span={12}
                                                                                          style={{
                                                                                            paddingTop: '20px',
                                                                                            display: 'flex',
                                                                                            justifyContent: 'center',
                                                                                          }}
                                                                                        >
                                                                                          <Card
                                                                                            onClick={() => {
                                                                                              handleOptionClick(option.id, item?.id, option.parentId, item?.parent, option?.originalMasterId);
                                                                                              setSelectedOptions((prevSelectedOptions) => ({
                                                                                                ...prevSelectedOptions,
                                                                                                [item?.id]: { option_id: option.id, parent_id: option.parentId },
                                                                                              }));
                                                                                            }}
                                                                                            style={{
                                                                                              backgroundColor: masterParentIdData !== 0 && selectedOptionId === option.id ? '#daebfc' : '#FFFFFF',
                                                                                              borderRadius: '20px',
                                                                                              padding: '0px',
                                                                                              textAlign: 'center',
                                                                                              height: '100%',
                                                                                              width: '100%',
                                                                                              display: 'flex',
                                                                                              cursor: "pointer",
                                                                                              flexDirection: 'column', // Set to column if you want text/image stacked
                                                                                              justifyContent: 'center', // Center vertically
                                                                                              alignItems: 'center', // Center horizontally
                                                                                              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // 3D shadow effect
                                                                                              transition: 'transform 0.2s ease, box-shadow 0.2s ease', // Smooth animation
                                                                                            }}
                                                                                            onMouseEnter={(e) => {
                                                                                              if (selectedOptionId !== option.id) {
                                                                                                e.currentTarget.style.transform = 'translateY(-5px)';
                                                                                                e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.3)';
                                                                                              }
                                                                                            }}
                                                                                            onMouseLeave={(e) => {
                                                                                              if (selectedOptionId !== option.id) {
                                                                                                e.currentTarget.style.transform = 'translateY(0px)';
                                                                                                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
                                                                                              }
                                                                                            }}
                                                                                          >
                                                                                            <Text
                                                                                              style={{
                                                                                                fontSize: '16px',
                                                                                                // backgroundColor: 'yellow',
                                                                                                textAlign: 'center',
                                                                                                padding: '30px', // Add padding for better spacing
                                                                                              }}
                                                                                            >
                                                                                              {option?.masterName}
                                                                                            </Text>
                                                                                          </Card>

                                                                                        </Grid.Col>
                                                                                      ))
                                                                                  ) : (
                                                                                    <Text
                                                                                      style={{
                                                                                        textAlign: 'center',
                                                                                        paddingTop: '20px',
                                                                                        paddingLeft: '20px',
                                                                                        paddingRight: '20px',
                                                                                        paddingBottom: '50px',
                                                                                        color: 'gray',
                                                                                      }}
                                                                                    >
                                                                                      No data available
                                                                                    </Text>
                                                                                  )}
                                                                                </Grid>

                                                                              </div>

                                                        {/* <div className='main_wheel_containerTwo'>


                                                          <Center style={{ marginTop: "70px" }}>
                                                            <Text style={{ fontWeight: "500", fontSize: "18px", textAlign: "center", color: "#228be6" }}>
                                                              {selectedSectionTwo.name}
                                                            </Text>
                                                          </Center>

                                                          <div className='main_wheelTwo'>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '45vh' }}>
                                                              <Box
                                                                sx={{
                                                                  display: 'flex',
                                                                  justifyContent: 'center',
                                                                  alignItems: 'center',
                                                                  height: '100vh',
                                                                  backgroundColor: '#00bfa5',
                                                                }}
                                                                onMouseMove={handleMouseMoveTwo}
                                                                onMouseUp={handleMouseUpOrLeaveTwo}
                                                                onMouseLeave={handleMouseUpOrLeaveTwo}
                                                                onTouchMove={handleTouchMoveTwo}
                                                                onTouchEnd={handleTouchEndTwo}
                                                                onTouchStart={handleTouchStartTwo}
                                                              >
                                                                <div className="wheel-containerTwo"
                                                                  onMouseMove={handleMouseMoveTwo}
                                                                  onMouseUp={handleMouseUpOrLeaveTwo}
                                                                  onMouseLeave={handleMouseUpOrLeaveTwo}
                                                                  onTouchMove={handleTouchMoveTwo}
                                                                  onTouchEnd={handleTouchEndTwo}
                                                                >
                                                                  <div
                                                                    className="wheelTwo"
                                                                    ref={wheelRefTwo}
                                                                    style={{
                                                                      transform: `rotate(${currentAngleTwo}deg)`, transition: 'transform 0.1s ease-out',
                                                                      userSelect: "none"
                                                                    }} // Smooth transition
                                                                    onMouseDown={handleMouseDownTwo}
                                                                    onTouchStart={handleTouchStartTwo}
                                                                  >
                                                                    <div className="segmentTwo segment1Two">Looking for a Job</div>
                                                                    <div className="segmentTwo segment2Two">Career Guidance</div>
                                                                    <div className="segmentTwo segment3Two">Looking for a Course</div>
                                                                  </div>
                                                                  <div className="arrowTwo"></div>
                                                                </div>
                                                              </Box>
                                                            </div>

                                                          </div>
                                                        </div> */}



                                                      </>

                                                    ) : (

                                                      <>
                                                        {(item.id == 59) ? (
                                                          <>
                                                            <div
                                                              style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                overflowY: 'auto',
                                                                maxHeight: '55vh',
                                                                flex: 1,
                                                              }}
                                                            >
                                                              {item?.options?.filter(option =>
                                                                option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                                              )?.length > 0 ? (
                                                                item.options
                                                                  .filter(option => option.masterName.toLowerCase().includes(searchQuery.toLowerCase()))
                                                                  .map((option, optionIndex) => (
                                                                    <label
                                                                      key={optionIndex}
                                                                      style={{
                                                                        alignItems: 'center',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignSelf: 'center',
                                                                        marginRight: '8px',
                                                                        marginLeft: '8px',
                                                                      }}
                                                                    >
                                                                      <Chip
                                                                        style={{ marginTop: '10px' }}
                                                                        checked={
                                                                          masterParentIdData !== 0
                                                                            ? selectedOptions[item?.id]?.option_id === option.id
                                                                            : ""
                                                                        }
                                                                        onChange={() =>
                                                                          setSelectedOptions(prevSelectedOptions => ({
                                                                            ...prevSelectedOptions,
                                                                            [item?.id]: {
                                                                              option_id: option.id,
                                                                              parent_id: option.parentId,
                                                                            },
                                                                          }))
                                                                        }
                                                                        size="lg"
                                                                        onClick={() =>
                                                                          handleOptionClick(option.id, item?.id, option.parentId, item?.parent, option?.originalMasterId)
                                                                        }
                                                                      >
                                                                        <Center>
                                                                          <Text
                                                                            style={{
                                                                              textAlign: 'center',
                                                                              textWrap: 'wrap',
                                                                              marginLeft: '0px',
                                                                              fontSize: '14px',
                                                                              paddingTop: '18px',
                                                                              height: '60px',
                                                                              width: '280px',
                                                                            }}
                                                                          >
                                                                            {option.masterName}
                                                                          </Text>
                                                                        </Center>
                                                                      </Chip>
                                                                    </label>
                                                                  ))
                                                              ) : (
                                                                <Text
                                                                  style={{
                                                                    textAlign: 'center',
                                                                    paddingTop: '20px',
                                                                    paddingLeft: '20px',
                                                                    paddingRight: '20px',
                                                                    paddingBottom: '50px',
                                                                    color: 'gray',
                                                                  }}
                                                                >
                                                                  No data available
                                                                </Text>
                                                              )}
                                                            </div>



                                                          </>
                                                        ) : (

                                                          <>
                                                            {(item.id == 51) ? (

                                                              //                                                         <div className="container_complete">
                                                              //                                                           <div className="year-selector">
                                                              //                                                             <button className="button" onClick={() => handleDecrement(item)} aria-label="Decrement year">
                                                              //                                                               -
                                                              //                                                             </button>
                                                              //                                                             {/* <span id="year-selector" className="year-display">
                                                              //   {year === null ? "2024" : year}
                                                              // </span> */}
                                                              //                                                             <span
                                                              //                                                               id="year-selector"
                                                              //                                                               className={`year-display ${year === 2025 ? "blue-year" : year === null ? "gray-year" : "blue-year"
                                                              //                                                                 }`}
                                                              //                                                             >
                                                              //                                                               {year === null ? "2024" : year}
                                                              //                                                             </span>
                                                              //                                                             <button className="button" onClick={() => handleIncrement(item)} aria-label="Increment year">
                                                              //                                                               +
                                                              //                                                             </button>
                                                              //                                                           </div>


                                                              //                                                           <div className="year-options">
                                                              //                                                             {item?.options
                                                              //                                                               ?.filter((option) =>
                                                              //                                                                 option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                                              //                                                               )
                                                              //                                                               .map((option, optionIndex) => (
                                                              //                                                                 <span
                                                              //                                                                   key={option.id}
                                                              //                                                                   className={year === parseInt(option.masterName) ? "active" : ""}
                                                              //                                                                 >
                                                              //                                                                   <div style={{ marginTop: "20px", }}>{option.masterName}</div>
                                                              //                                                                 </span>
                                                              //                                                               ))}
                                                              //                                                           </div>

                                                              //                                                         </div>
                                                              <>
                                                                <div style={{
                                                                  // border: "1px solid green",
                                                                  display: "flex",
                                                                  alignItems: "center",
                                                                  justifyContent: "center",
                                                                  marginTop: "10%"
                                                                }}>
                                                                  {/* Buttons for controlling the slides */}

                                                                  <HiMinus
                                                                    fontSize={50}
                                                                    onClick={handlePrevSlide}
                                                                    style={{
                                                                      cursor: "pointer",
                                                                      position: "absolute",
                                                                      left: "10%",
                                                                      transform: "translateY(-120%)",
                                                                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                                                                      borderRadius: "50%",
                                                                      padding: "7px",
                                                                    }}
                                                                  />

                                                                  {/* Plus Button */}
                                                                  <HiPlus
                                                                    fontSize={50}
                                                                    onClick={handleNextSlide}
                                                                    style={{
                                                                      cursor: "pointer",
                                                                      position: "absolute",
                                                                      right: "10%",
                                                                      transform: "translateY(-120%)",
                                                                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                                                                      borderRadius: "50%",
                                                                      padding: "7px",
                                                                    }}
                                                                  />



                                                                  <Swiper
                                                                    direction="vertical"
                                                                    onSwiper={(swiper) => {
                                                                      swiperRef.current = swiper;
                                                                    }}
                                                                    onSlideChange={(swiper) => {
                                                                      const newIndex = swiper.activeIndex;
                                                                      setActiveSlideIndex(newIndex); // Update the renamed state
                                                                      setSelectedYear(years[newIndex]); // Update the selected year
                                                                      // handleYearClick()
                                                                    }}
                                                                    className="mySwiper"
                                                                    style={{
                                                                      height: "50vh",
                                                                      width: "50%",
                                                                    }}
                                                                    slidesPerView={5}
                                                                    centeredSlides={true}
                                                                    initialSlide={49}
                                                                    spaceBetween={5}
                                                                    allowTouchMove={false}
                                                                  >
                                                                    {years.map((year, index) => (
                                                                      <SwiperSlide
                                                                        key={year}
                                                                        // onClick={() => handleYearClick(year)}
                                                                        style={{
                                                                          height: "100%",
                                                                          display: "flex",
                                                                          justifyContent: "center",
                                                                          alignItems: "center",
                                                                          fontSize: activeSlideIndex === index ? "25px" : "20px", // Updated variable
                                                                          backgroundColor: "white",
                                                                          color: activeSlideIndex === index && masterParentIdData ? "black" : "gray", // Updated variable
                                                                          transition: "background-color 0.3s ease",

                                                                          borderRadius: "50px",
                                                                          boxShadow:
                                                                            activeSlideIndex === index
                                                                              ? "0px 4px 8px rgba(0, 0, 0, 0.2)"
                                                                              : null, // Updated variable
                                                                        }}
                                                                      >
                                                                        {year}
                                                                      </SwiperSlide>
                                                                    ))}
                                                                  </Swiper>
                                                                </div>
                                                              </>
                                                            ) : (
                                                              <>

                                                                {(item.id == 49 || item.id == 66) ? (

                                                                  <>
                                                                    <div className="main_container_naps">

                                                                      <div className="toggle_container">
                                                                        <section className="container_naps">
                                                                          <span className="no_btn">No</span>
                                                                          <div className="toggle_btn">
                                                                            <input
                                                                              type="checkbox"
                                                                              className="toggle-input"
                                                                              onChange={() => handleChange2(item, event)}
                                                                              defaultChecked={isChecked}
                                                                            />
                                                                            <span>
                                                                              {isChecked && !hide ? (
                                                                                <img
                                                                                  className="yes"
                                                                                  style={{ display: hide ? "none" : "block" }}
                                                                                  src={masterParentIdData ? Yes : Yes2}
                                                                                  alt="Yes"
                                                                                />
                                                                              ) : (
                                                                                <img
                                                                                  className="no"
                                                                                  style={{ display: hide ? "none" : "block" }}
                                                                                  src={masterParentIdData ? No : Remove}
                                                                                  alt="No"
                                                                                />
                                                                              )}
                                                                            </span>
                                                                          </div>
                                                                          <span className="yes_btn">Yes</span>
                                                                        </section>
                                                                      </div>
                                                                    </div>
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    {(item.id == 1 || item.id == 61 || item.id == 62 || item.id == 67 || item.id == 48) ? (
                                                                      <>

<div>
                              <Grid
                                gutter={{ base: 5, xs: 'md', md: 'md', xl: 30 }}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  overflow: 'auto',
                                  //  height: '100%',
                                  maxHeight: '71vh', // Respect the container's max-height
                                  width: '100%',
                                  padding: "5px",
                                  alignItems: item?.options?.length < 3 ? 'center' : 'flex-start',
                                }}
                              >
                                <>
                                  {item?.options?.filter(option =>
                                    option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                  )?.length > 0 ? (
                                    item.options
                                      .filter(option => option.masterName.toLowerCase().includes(searchQuery.toLowerCase()))
                                      .sort((a, b) => b.active - a.active) 
                                      .map((option, optionIndex) => (
                                        <Grid.Col
                                          key={optionIndex}
                                          span={12}
                                          style={{
                                            paddingTop: '12px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <Card
                                            onClick={() => {
                                              handleOptionClick(option.id, item?.id, option.parentId, item?.parent, option?.originalMasterId);
                                              setSelectedOptions(prevSelectedOptions => ({
                                                ...prevSelectedOptions,
                                                [item?.id]: { "option_id": option.id, "parent_id": option.parentId },
                                              }));
                                            }}
                                            style={{
                                              backgroundColor: masterParentIdData !== 0 && selectedOptionId === option.id ? '#daebfc' : '#FFFFFF',
                                              borderRadius: '20px',
                                              padding: '0px',
                                              textAlign: 'center',
                                              height: '100%',
                                              width: '100%',
                                              display: 'flex',
                                              cursor: "pointer",
                                              flexDirection: 'row',
                                              justifyContent: 'flex-start',
                                              alignItems: 'center',
                                              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // 3D shadow effect
                                              transition: 'transform 0.2s ease, box-shadow 0.2s ease', // Smooth animation
                                            }}
                                            onMouseEnter={(e) => {
                                              if (selectedOptionId !== option.id) { // Only apply hover effects if not selected
                                                e.currentTarget.style.transform = 'translateY(-5px)';
                                                e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.3)';
                                              }
                                            }}
                                            onMouseLeave={(e) => {
                                              if (selectedOptionId !== option.id) { // Reset hover effects if not selected
                                                e.currentTarget.style.transform = 'translateY(0px)';
                                                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
                                              }
                                            }}
                                          >
                                            <img
                                              // src={option?.logoVirtualPath}
                                              src={option?.logoVirtualPath ? option?.logoVirtualPath : Worker}
                                              alt="Logo"
                                              style={{
                                                width: '100px',
                                                height: '90px',
                                                borderRadius: '20px',
                                                marginRight: '20px',
                                                marginLeft: '10px',
                                                marginTop: '5px',
                                                marginBottom: '5px',
                                              }}
                                            />
                                            <Text style={{ fontSize: '15px' }}>
                                              {option?.masterName}
                                            </Text>
                                          </Card>
                                        </Grid.Col>
                                      ))
                                  ) : (
                                    <Text
                                      style={{
                                        textAlign: 'center',
                                        paddingTop: '20px',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        paddingBottom: '50px',
                                        color: 'gray',
                                      }}
                                    >
                                      No data available
                                    </Text>
                                  )}
                                  <div>
                                    <Text style={{ color: "white", marginTop: "50px" }}>.</Text>
                                  </div>

                                </>
                              </Grid>

                            </div>



                                                                        {/* {console.log("itemmmmm" + item?.options?.length)}
                                                                        <section className="tranding"  >
                                                                          <div className="container_trade" >

                                                                            <Swiper

                                                                              grabCursor={true}
                                                                              centeredSlides={true}
                                                                              loop={false}
                                                                              slidesPerView={item?.options?.filter(option =>
                                                                                option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                                                              ).length > 4 ? 2.5 : item?.options?.filter(option =>
                                                                                option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                                                              ).length == 1 ? 1 : "auto"}
                                                                              initialSlide={2}
                                                                              effect="coverflow"
                                                                              coverflowEffect={{
                                                                                rotate: 0,
                                                                                stretch: 0,
                                                                                depth: 200,
                                                                                modifier: 2.5,
                                                                              }}
                                                                              autoplay={{
                                                                                delay: 2500,
                                                                                disableOnInteraction: false,
                                                                              }}
                                                                              navigation={{ nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" }}
                                                                              style={{ minHeight: "55vh" }}
                                                                              onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                                                                            >
                                                                              {item?.options?.length > 0 ? item?.options
                                                                                ?.filter(option => option.masterName.toLowerCase().includes(searchQuery.toLowerCase()))?.map((slide, index) => (
                                                                                  <SwiperSlide key={slide?.id} className="tranding-slide"
                                                                                    onClick={() => {
                                                                                      setSelectedSlide(slide?.id)
                                                                                      setSelectedHeading(slide?.masterName);
                                                                                      handleOptionClick(slide.id, item?.id, slide.parentId, item?.parent, slide?.originalMasterId);
                                                                                      setSelectedOptions(prevSelectedOptions => ({
                                                                                        ...prevSelectedOptions,
                                                                                        [item?.id]: { "option_id": slide.id, "parent_id": slide.parentId },
                                                                                      }));

                                                                                      const swiperInstance = document.querySelector('.swiper').swiper;
                                                                                      swiperInstance.autoplay.stop();

                                                                                    }}

                                                                                  >
                                                                                    <div
                                                                                      className="tranding-slide-img"
                                                                                      style={selectedSlide && selectedSlide === slide?.id ? { border: "3px solid grey", boxShadow: "none", backgroundColor: "#cfd8dc", padding: "7px" } : null}
                                                                                    >
                                                                                      <div>
                                                                                        <img src={slide?.logoVirtualPath ? slide?.logoVirtualPath : Worker}
                                                                                          className={"trade_img"}
                                                                                        />
                                                                                      </div>
                                                                                      <div className='img_heading'>
                                                                                        {
                                                                                          index === activeIndex && <div >
                                                                                            <span style={{ textAlign: "center" }}>{slide.masterName}</span>
                                                                                          </div>
                                                                                        }

                                                                                      </div>

                                                                                    </div>
                                                                                    {
                                                                                      index === activeIndex && selectedSlide ? <div
                                                                                        className='selectHeading'
                                                                                      >
                                                                                        <span style={{ textAlign: "center" }}>{selectedHeading}</span>
                                                                                      </div> : null
                                                                                    }

                                                                                  </SwiperSlide>
                                                                                )) : null}

                                                                              {
                                                                                item?.options?.filter(option =>
                                                                                  option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                                                                ).length == 0 && <div style={{ height: "50vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>No data available</div>
                                                                              }
                                                                            </Swiper>

                                                                          </div>
                                                                        </section> */}



                                                                      </>

                                                                    ) : (
                                                                      <>
                                                                        {(item.id == 63) ? (
                                                                          <div
                                                                            style={{
                                                                              height: '62vh', // Limit the height of the parent container
                                                                              // overflowY: 'auto', // Apply scroll behavior to the parent
                                                                              display: 'flex',
                                                                              flexDirection: 'column',
                                                                              marginTop: "10px"
                                                                            }}
                                                                          >
                                                                            <Grid
                                                                              gutter={{ base: 5, xs: 'md', md: 'md', xl: 30 }}
                                                                              style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                // overflowY: 'auto',
                                                                                maxHeight: '65vh',
                                                                                padding: "5px",
                                                                                alignItems: item?.options?.length < 3 ? 'center' : 'flex-start',
                                                                              }}
                                                                            >
                                                                              {item?.options?.filter(option =>
                                                                                option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                                                              )?.length > 0 ? (
                                                                                item.options
                                                                                  .filter(option => option.masterName.toLowerCase().includes(searchQuery.toLowerCase()))
                                                                                  .map((option, optionIndex) => (
                                                                                    <Grid.Col
                                                                                      key={optionIndex}
                                                                                      span={12}
                                                                                      style={{
                                                                                        paddingTop: '10px',
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center',
                                                                                        // backgroundColor:"yellow"
                                                                                      }}
                                                                                    >
                                                                                      <Card
                                                                                        onClick={() => {
                                                                                          handleOptionClick(option.id, item?.id, option.parentId, item?.parent, option?.originalMasterId);
                                                                                          setSelectedOptions(prevSelectedOptions => ({
                                                                                            ...prevSelectedOptions,
                                                                                            [item?.id]: { "option_id": option.id, "parent_id": option.parentId },
                                                                                          }));
                                                                                        }}
                                                                                        style={{
                                                                                          backgroundColor: masterParentIdData !== 0 && selectedOptionId === option.id ? '#daebfc' : '#FFFFFF',
                                                                                          // selectedOptionId === option.id ? 'lightblue' : '#FFFFFF', 
                                                                                          borderRadius: '20px',
                                                                                          padding: '10px',
                                                                                          textAlign: 'center',
                                                                                          height: '100%',
                                                                                          width: '100%',
                                                                                          display: 'flex',
                                                                                          cursor: "pointer",
                                                                                          flexDirection: 'row',
                                                                                          justifyContent: 'flex-start',
                                                                                          alignItems: 'center',
                                                                                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // 3D shadow effect
                                                                                          transition: 'transform 0.2s ease, box-shadow 0.2s ease', // Smooth animation
                                                                                        }}
                                                                                        onMouseEnter={(e) => {
                                                                                          if (selectedOptionId !== option.id) { // Only apply hover effects if not selected
                                                                                            e.currentTarget.style.transform = 'translateY(-5px)';
                                                                                            e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.3)';
                                                                                          }
                                                                                        }}
                                                                                        onMouseLeave={(e) => {
                                                                                          if (selectedOptionId !== option.id) { // Reset hover effects if not selected
                                                                                            e.currentTarget.style.transform = 'translateY(0px)';
                                                                                            e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
                                                                                          }
                                                                                        }}
                                                                                      >
                                                                                        <img
                                                                                          src={option?.logoVirtualPath}
                                                                                          alt="Logo"
                                                                                          style={{
                                                                                            width: '110px',
                                                                                            height: '60px',
                                                                                            // objectFit: 'contain', // Maintain aspect ratio
                                                                                            marginRight: '20px',
                                                                                            marginLeft: '20px',
                                                                                            marginTop: '10px',
                                                                                            marginBottom: '10px',
                                                                                          }}
                                                                                        />
                                                                                        <Text style={{ fontSize: '15px' }}>
                                                                                          {option?.masterName}
                                                                                        </Text>
                                                                                      </Card>
                                                                                    </Grid.Col>
                                                                                  ))
                                                                              ) : (
                                                                                <Text
                                                                                  style={{
                                                                                    textAlign: 'center',
                                                                                    paddingTop: '20px',
                                                                                    paddingLeft: '20px',
                                                                                    paddingRight: '20px',
                                                                                    paddingBottom: '50px',
                                                                                    color: 'gray',
                                                                                  }}
                                                                                >
                                                                                  No data available
                                                                                </Text>
                                                                              )}
                                                                            </Grid>

                                                                          </div>
                                                                        ) : (

                                                                          <>
                                                                            {(item.id == 65 || item.id == 58) ? (
                                                                              <div
                                                                                style={{
                                                                                  height: '62vh', // Limit the height of the parent container
                                                                                  // overflowY: 'auto', // Apply scroll behavior to the parent
                                                                                  display: 'flex',
                                                                                  flexDirection: 'column',
                                                                                  // backgroundColor:"yellow",
                                                                                  paddingTop: "15px",
                                                                                }}
                                                                              >
                                                                                <Grid
                                                                                  gutter={{ base: 5, xs: 'md', md: 'md', xl: 30 }}
                                                                                  style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    // overflowY: 'auto',
                                                                                    maxHeight: '65vh',
                                                                                    padding: "5px",
                                                                                    alignItems: item?.options?.length < 3 ? 'center' : 'flex-start',
                                                                                  }}
                                                                                >
                                                                                  {item?.options?.filter(option =>
                                                                                    option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                                                                  )?.length > 0 ? (
                                                                                    item.options
                                                                                      .filter(option => option.masterName.toLowerCase().includes(searchQuery.toLowerCase()))
                                                                                      .map((option, optionIndex) => (
                                                                                        <Grid.Col
                                                                                          key={optionIndex}
                                                                                          span={12}
                                                                                          style={{
                                                                                            paddingTop: '20px',
                                                                                            display: 'flex',
                                                                                            justifyContent: 'center',
                                                                                          }}
                                                                                        >
                                                                                          <Card
                                                                                            onClick={() => {
                                                                                              handleOptionClick(option.id, item?.id, option.parentId, item?.parent, option?.originalMasterId);
                                                                                              setSelectedOptions((prevSelectedOptions) => ({
                                                                                                ...prevSelectedOptions,
                                                                                                [item?.id]: { option_id: option.id, parent_id: option.parentId },
                                                                                              }));
                                                                                            }}
                                                                                            style={{
                                                                                              backgroundColor: masterParentIdData !== 0 && selectedOptionId === option.id ? '#daebfc' : '#FFFFFF',
                                                                                              borderRadius: '20px',
                                                                                              padding: '0px',
                                                                                              textAlign: 'center',
                                                                                              height: '100%',
                                                                                              width: '100%',
                                                                                              display: 'flex',
                                                                                              cursor: "pointer",
                                                                                              flexDirection: 'column', // Set to column if you want text/image stacked
                                                                                              justifyContent: 'center', // Center vertically
                                                                                              alignItems: 'center', // Center horizontally
                                                                                              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // 3D shadow effect
                                                                                              transition: 'transform 0.2s ease, box-shadow 0.2s ease', // Smooth animation
                                                                                            }}
                                                                                            onMouseEnter={(e) => {
                                                                                              if (selectedOptionId !== option.id) {
                                                                                                e.currentTarget.style.transform = 'translateY(-5px)';
                                                                                                e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.3)';
                                                                                              }
                                                                                            }}
                                                                                            onMouseLeave={(e) => {
                                                                                              if (selectedOptionId !== option.id) {
                                                                                                e.currentTarget.style.transform = 'translateY(0px)';
                                                                                                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
                                                                                              }
                                                                                            }}
                                                                                          >
                                                                                            <Text
                                                                                              style={{
                                                                                                fontSize: '16px',
                                                                                                // backgroundColor: 'yellow',
                                                                                                textAlign: 'center',
                                                                                                padding: '30px', // Add padding for better spacing
                                                                                              }}
                                                                                            >
                                                                                              {option?.masterName}
                                                                                            </Text>
                                                                                          </Card>

                                                                                        </Grid.Col>
                                                                                      ))
                                                                                  ) : (
                                                                                    <Text
                                                                                      style={{
                                                                                        textAlign: 'center',
                                                                                        paddingTop: '20px',
                                                                                        paddingLeft: '20px',
                                                                                        paddingRight: '20px',
                                                                                        paddingBottom: '50px',
                                                                                        color: 'gray',
                                                                                      }}
                                                                                    >
                                                                                      No data available
                                                                                    </Text>
                                                                                  )}
                                                                                </Grid>

                                                                              </div>


                                                                            ) : (
                                                                              <div style={{
                                                                                //marginTop: '10px',
                                                                                //backgroundColor: "pink",
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                overflowY: 'auto',
                                                                                maxHeight: '55vh',
                                                                                //marginBottom: '80px',
                                                                                // marginRight: "10px",
                                                                                flex: 1,
                                                                                //overflowY: 'auto',
                                                                                //zIndex: "1",
                                                                              }}>

                                                                                {item?.options?.filter(option =>
                                                                                  option.masterName.toLowerCase().includes(searchQuery.toLowerCase())
                                                                                )?.length > 0 ? (
                                                                                  item.options
                                                                                    .filter(option => option.masterName.toLowerCase().includes(searchQuery.toLowerCase()))
                                                                                    .map((option, optionIndex) => (
                                                                                      <label key={optionIndex} style={{

                                                                                        alignItems: "center",
                                                                                        display: 'flex',
                                                                                        justifyContent: "center",
                                                                                        alignSelf: "center",
                                                                                        marginRight: '8px',
                                                                                        marginLeft: '8px',

                                                                                        //width: "100%",
                                                                                      }}>
                                                                                        <Chip
                                                                                          style={{ marginTop: '10px' }}
                                                                                          checked={masterParentIdData != 0 ? selectedOptions[item?.id]?.option_id === option.id : ""}
                                                                                          onChange={() => setSelectedOptions(prevSelectedOptions => ({
                                                                                            ...prevSelectedOptions,
                                                                                            [item?.id]: { "option_id": option.id, "parent_id": option.parentId },
                                                                                          }))}
                                                                                          size='lg'
                                                                                          onClick={() => handleOptionClick(option.id, item?.id, option.parentId, item?.parent, option?.originalMasterId)}
                                                                                        >
                                                                                          <Center>
                                                                                            <Text
                                                                                              style={{
                                                                                                textAlign: 'center',
                                                                                                textWrap: "wrap",
                                                                                                marginLeft: '0px',
                                                                                                fontSize: '14px',
                                                                                                paddingTop: '18px',
                                                                                                height: '60px',
                                                                                                width: '280px',
                                                                                              }}
                                                                                            >
                                                                                              {option.masterName}
                                                                                            </Text>
                                                                                          </Center>



                                                                                        </Chip>
                                                                                      </label>
                                                                                    ))
                                                                                ) : (
                                                                                  <Text style={{ textAlign: 'center', paddingTop: '20px', paddingLeft: "20px", paddingRight: '20px', paddingBottom: '50px', color: 'gray' }}>
                                                                                    No data available
                                                                                  </Text>
                                                                                )}
                                                                              </div>
                                                                            )}
                                                                          </>

                                                                        )}
                                                                      </>

                                                                    )}
                                                                  </>

                                                                )}
                                                              </>



                                                            )}
                                                          </>



                                                        )}
                                                      </>



                                                    )}
                                                  </>



                                                )}

                                              </>

                                            )
                                            }
                                          </>




                                        )}
                                      </>


                                    )}
                                  </>


                                )}
                              </>
                            )}
                          </>



                        )}

                      </>


                    )}



                    {/* ///////////////////////////////////////////////// */}


                    <div style={{ marginBottom: "80px" }}>
                      <Text style={{ color: "white" }}>.</Text>
                    </div>
                  </Card>
                </div>
              );
            })
          ) : (
            <Center style={{ marginTop: "150px" }}>
              <Loader color="#0180ff" size={30} />
            </Center>
          )}
        </div>

        {/* //////////////////////////////////// */}
        <div>
          {proData?.length > 0 ? (
            proData?.map((item, index) => {
              return (
                <div key={index}>
                  <div style={{
                    position: "fixed",
                    width: "100%",
                    height: "10%",
                    maxWidth: "600px",
                    bottom: "0px",
                    background: "white",
                    padding: "0px",
                    display: "grid",
                    alignItems: "center",
                    justifyContent: "'center",
                    alignContent: "center",
                  }}>
                    {isLastPage ? (
                      <div>
                        <Paper radius="md" ml={'5px'} mr={'5px'} mt={'20px'} mb={'10px'}>
                          <div style={{ display: "flex", flexDirection: "row" }}>

                            <Button onClick={handleBackPage} w="30%" style={{ marginLeft: "15px", border: "none", marginRight: "0px", cursor: "pointer", background: "#ccc", color: '#000' }}>
                              <Text c="white" size="lg" mb={5} mt={5} fw={600}>
                                <Trans>Back</Trans>
                              </Text>
                            </Button>
                            <Button onClick={handleSubmit} w="100%" style={{ marginLeft: "10px", border: "none", marginRight: "10px", cursor: "pointer", background: "#0B9A08", color: "#fff", width: "100%" }}>
                              <Text c="white" size="lg" mb={5} mt={5} fw={600}>
                                <Trans>Submit</Trans>
                              </Text>
                            </Button>
                          </div>
                        </Paper>
                      </div>
                    ) : (
                      <div>
                        <Paper radius="md" ml={'5px'} mr={'5px'} mt={'20px'} mb={'10px'}>
                          <div style={{ display: "flex", flexDirection: "row" }}>

                            {currentPage > 0 && (
                              <Button onClick={handleBackPage} w="30%" style={{ marginLeft: "15px", border: "none", marginRight: "0px", cursor: "pointer", background: "#ccc", color: '#000' }}>
                                <Text c="white" size="lg" mb={5} mt={5} fw={600}>
                                  <Trans>Back</Trans>
                                </Text>
                              </Button>
                            )}
                            {/* {(item.id == 43) ? (
                              <Button
                                onClick={() => {
                                  handleNextPageCareer(item?.id);


                                }}
                                w="100%" style={{ marginLeft: "10px", border: "none", marginRight: "10px", cursor: "pointer", background: "#0B9A08", color: "#fff", width: "100%" }}>
                                <Text c="white" size="lg" mb={5} mt={5} fw={600}>
                                  <Trans>Next</Trans>
                                </Text>
                              </Button>
                            ) : ( */}
                              <Button onClick={handleNextPage} w="100%" style={{ marginLeft: "10px", border: "none", marginRight: "10px", cursor: "pointer", background: "#0B9A08", color: "#fff", width: "100%" }}>
                                <Text c="white" size="lg" mb={5} mt={5} fw={600}>
                                  <Trans>Next</Trans>
                                </Text>
                              </Button>
                            {/* )} */}

                          </div>
                        </Paper>
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <Center>
              <>
              </>
            </Center>
          )}
        </div>
      </div>
    </>
  );
};

export default OnboardingQuestions;

///////////////////////
