import React,{useEffect, useState} from 'react'
import Jobscard from '../components/JobsCard'
import {useSelector} from 'react-redux'
import {useGetAllJobsQuery} from '../redux/api/apiSlice'
import {Skeleton} from '@mantine/core'

const Alljobsection = () => {
    const token = useSelector((state) => state?.user.token)
    const [alljobs, setAllJobs] = useState([])
    const [loading, setloading] = useState(true)


    const { data: jobdata, isLoading, isError, isFetching, refetch } = useGetAllJobsQuery({
        skip: !token
    })

    useEffect(() => {
        if (token) {
            refetch();
        }
    }, [token, refetch]);


    useEffect(() => {
        if (jobdata) {
            setAllJobs(jobdata)
            setloading(false)
        }
    }, [jobdata]);

  

  return (
    <div style={{width:"100%"}}>
        {loading ? (
                <>
                    <div style={{marginBottom:"20px"}}>
                            <Skeleton height={50} circle mb="xl" />
                            <Skeleton height={8} radius="xl" />
                            <Skeleton height={8} mt={6} radius="xl" />
                            <Skeleton height={8} mt={6} width="100%" radius="xl" /> 
                    </div>
                    <div style={{marginBottom:"20px"}}>
                            <Skeleton height={50} circle mb="xl" />
                            <Skeleton height={8} radius="xl" />
                            <Skeleton height={8} mt={6} radius="xl" />
                            <Skeleton height={8} mt={6} width="100%" radius="xl" /> 
                    </div>
                    <div>
                            <Skeleton height={50} circle mb="xl" />
                            <Skeleton height={8} radius="xl" />
                            <Skeleton height={8} mt={6} radius="xl" />
                            <Skeleton height={8} mt={6} width="100%" radius="xl" /> 
                    </div>
                </>
            ) : (
         <>
          {alljobs.length > 0 && (
              alljobs.map(job=>{
                  return  <Jobscard
                     id={job?.id}
                     title={job?.jobTitle}
                     company={job?.companyName}
                     trade={job?.trade?.masterName}
                     location={job?.location?.masterName}
                     state={job?.location?.parentModel?.masterName}
                     education={job?.education?.masterName}
                     experience={job?.experience?.masterName}
                     salary={job?.salary?.masterName}
                     jobTypeId={job?.jobPostingTypeId}
                     logo={job?.logoVirtualPath ? job?.logoVirtualPath : job?.employer?.logoVirtualPath }
                  />
              })
          )}

          <div style={{marginTop: "60px", color:"white"}}>
           <h6>.</h6>
          </div>

         </>
        )}

       
    </div>
  )
}

export default Alljobsection